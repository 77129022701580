export const fakeDataForSignals = [
	{
		id: '663b6507-3dc8-43de-83e5-2c2f3f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'EUR',
			ccy: 'SEK',
			code: 'EURSEK',
			decPrec: 5,
			displayName: 'EURSEK',
			fullName: 'Euro vs Swedish Krona',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'SEK',
			tickSize: 0.00001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 1,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b6507-3dc8-43de-83e5-2c2f3f6e2587',
			group_id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			product_id: '5afec08b-38fc-402b-9110-42ff3f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'XRPUSDT',
			trigger: null,
			chart:
				'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587-resize-315x152-663b65067ffb0-small.png',
			pia_chart: 'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587-resize-1180x800-663b6505dc729-small.png',
			pia_chart_590:
				'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587-resize-590x400-663b65063766f-small.png',
			chart_scale:
				'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587-resize-315x152-663b650692982-small.png',
			chart_large:
				'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587-resize-400x193-663b650656a28-small.png',
			chart_scale_large:
				'charts/pia-chart/XRPUSD_2024-05-08_13-41-35663b6505-5748-4976-9d99-2c2f3f6e2587-resize-400x193-663b65066c704-small.png',
			direction: 0,
			action: 0,
			buy_target_1: 0.5355,
			buy_target_2: 0.548,
			sell_target_1: 0.0,
			sell_target_2: 0.0,
			stop: 0.4865,
			res_1: 0.52,
			res_2: 0.535,
			res_3: 0.547,
			sup_1: 0.5117,
			sup_2: 0.5075,
			sup_3: 0.5,
			confidence: 90,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T11:38:11Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 16,
			original_status: 16,
			daily_free_trade_strategy: false,
			author: 'TIV',
			created: '2024-05-08T12:38:11',
			modified: '2024-05-08T12:41:57',
			modifiedUtc: '2024-05-08T11:41:57Z',
			direction_text: 'Bullish',
			action_text: 'BUY',
			status_text: 'Buy Limit',
			original_status_text: 'Buy Limit',
			buy_entry_target_1: 0.5005,
			sell_entry_target_1: null,
			locale: 'en_GB',
		},
		Group: {
			id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			category_id: '5b3df421-18c8-485d-b013-29c33f6e2587',
			name: 'Cryptocurrencies',
			slug: 'cryptocurrencies',
			Category: {
				id: '5b3df421-18c8-485d-b013-29c33f6e2587',
				name: 'Cryptocurrencies',
				slug: 'cryptocurrencies',
			},
		},
		Product: {
			id: '5afec08b-38fc-402b-9110-42ff3f6e2587',
			name: 'XRP',
			slug: 'xrp',
			scale_factor: 1.0,
			market_id_name: 'XRPUSDT',
			tradable_name: 'XRPUSDT',
			chart_widget: 'XRPUSDT',
			trading_view_symbol: 'XRPUSDT',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: ['We look to Buy at 0.5005'],
		SellPhrase: null,
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Support is located at 0.5000 and should stem dips to this area',
			'The medium term bias remains bullish',
			'Early pessimism  is likely to lead to losses although extended attempts lower are expected to fail',
			'We look for a temporary move lower',
			'Expect trading to remain mixed and volatile',
		],
		_rid: 'ZL9MAMXeixuJJ2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixuJJ2YAAAAAAA==/',
		_etag: '"1000a96e-0000-0d00-0000-663b65080000"',
		_attachments: 'attachments/',
		_ts: 1715168520,
	},
	{
		id: '663b6321-cea4-4822-b5d4-27063f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'AUD',
			ccy: 'JPY',
			code: 'AUDJPY',
			decPrec: 3,
			displayName: 'AUDJPY',
			fullName: 'Australian Dollar vs Japanese Yen',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'JPY',
			tickSize: 0.001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 2,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b6321-cea4-4822-b5d4-27063f6e2587',
			group_id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			product_id: '61b0ca3f-1f08-4abe-8151-63323f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'LTCUSD',
			trigger: null,
			chart:
				'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587-resize-315x153-663b63bb16ae4-small.png',
			pia_chart: 'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587-resize-1180x800-663b63ba70ea9-small.png',
			pia_chart_590:
				'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587-resize-590x400-663b63bac08b7-small.png',
			chart_scale:
				'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587-resize-315x153-663b63bb29e3b-small.png',
			chart_large:
				'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587-resize-400x194-663b63badfe6b-small.png',
			chart_scale_large:
				'charts/pia-chart/LTCUSD_2024-05-08_13-36-10663b63ba-4dd4-4c21-9346-28b13f6e2587-resize-400x194-663b63bb0244a-small.png',
			direction: 0,
			action: 0,
			buy_target_1: 89.85,
			buy_target_2: 90.85,
			sell_target_1: 0.0,
			sell_target_2: 0.0,
			stop: 81.45,
			res_1: 80.5,
			res_2: 82.0,
			res_3: 83.7,
			sup_1: 78.97,
			sup_2: 78.0,
			sup_3: 77.0,
			confidence: 80,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T11:30:42Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 15,
			original_status: 15,
			daily_free_trade_strategy: false,
			author: 'TIV ',
			created: '2024-05-08T12:30:42',
			modified: '2024-05-08T12:36:26',
			modifiedUtc: '2024-05-08T11:36:26Z',
			direction_text: 'Bullish',
			action_text: 'BUY',
			status_text: 'Buy Stop',
			original_status_text: 'Buy Stop',
			buy_entry_target_1: 83.85,
			sell_entry_target_1: null,
			locale: 'en_GB',
		},
		Group: {
			id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			category_id: '5b3df421-18c8-485d-b013-29c33f6e2587',
			name: 'Cryptocurrencies',
			slug: 'cryptocurrencies',
			Category: {
				id: '5b3df421-18c8-485d-b013-29c33f6e2587',
				name: 'Cryptocurrencies',
				slug: 'cryptocurrencies',
			},
		},
		Product: {
			id: '61b0ca3f-1f08-4abe-8151-63323f6e2587',
			name: 'Litecoin',
			slug: 'litecoin',
			scale_factor: 100.0,
			market_id_name: 'LTCUSD',
			tradable_name: '',
			chart_widget: '',
			trading_view_symbol: '',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: ['We look to Buy a break of 83.85'],
		SellPhrase: null,
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'A break of the recent high at 83.70 should result in a further move higher',
			'Broken out of the Head and Shoulders formation to the upside',
			'The primary trend remains bullish',
			'The medium term bias remains bullish',
			'The lack of interest is a concern for bears',
		],
		_rid: 'ZL9MAMXeixtzJ2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixtzJ2YAAAAAAA==/',
		_etag: '"10002908-0000-0d00-0000-663b63be0000"',
		_attachments: 'attachments/',
		_ts: 1715168190,
	},
	{
		id: '663b61f5-bdb4-442d-881b-23d13f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'USD',
			ccy: 'JPY',
			code: 'USDJPY',
			decPrec: 3,
			displayName: 'USDJPY',
			fullName: 'US Dollar vs Japanese Yen',
			feedId: 'VTFeed',
			flags: 258,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'JPY',
			tickSize: 0.001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 0,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b61f5-bdb4-442d-881b-23d13f6e2587',
			group_id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			product_id: '5afec0a4-8d5c-48ce-b429-438d3f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'ETH',
			trigger: null,
			chart:
				'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587-resize-315x153-663b61f445d17-small.png',
			pia_chart: 'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587-resize-1180x800-663b61f368747-small.png',
			pia_chart_590:
				'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587-resize-590x400-663b61f3ccc0c-small.png',
			chart_scale:
				'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587-resize-315x153-663b61f462fe2-small.png',
			chart_large:
				'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587-resize-400x194-663b61f405006-small.png',
			chart_scale_large:
				'charts/pia-chart/ETHUSD_2024-05-08_13-28-23663b61f3-af58-4069-8f6b-23d13f6e2587-resize-400x194-663b61f427d88-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 2905.0,
			sell_target_2: 2855.0,
			stop: 3185.0,
			res_1: 3030.0,
			res_2: 3070.0,
			res_3: 3120.0,
			sup_1: 2975.0,
			sup_2: 2940.0,
			sup_3: 2900.0,
			confidence: 80,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T11:17:06Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'TIV',
			created: '2024-05-08T12:17:06',
			modified: '2024-05-08T12:28:51',
			modifiedUtc: '2024-05-08T11:28:51Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 3105.0,
			locale: 'en_GB',
		},
		Group: {
			id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			category_id: '5b3df421-18c8-485d-b013-29c33f6e2587',
			name: 'Cryptocurrencies',
			slug: 'cryptocurrencies',
			Category: {
				id: '5b3df421-18c8-485d-b013-29c33f6e2587',
				name: 'Cryptocurrencies',
				slug: 'cryptocurrencies',
			},
		},
		Product: {
			id: '5afec0a4-8d5c-48ce-b429-438d3f6e2587',
			name: 'Ethereum',
			slug: 'ethereum',
			scale_factor: 1.0,
			market_id_name: 'ETH',
			tradable_name: '',
			chart_widget: 'ETHUSD',
			trading_view_symbol: 'ETHUSD',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 3105'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Daily signals are mildly bearish',
			'Preferred trade is to sell into rallies',
			'20 1 day EMA is at 3115',
			'We look for a temporary move higher',
			'The bearish engulfing candle on the 4 hour chart is negative for sentiment',
		],
		_rid: 'ZL9MAMXeixtdJ2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixtdJ2YAAAAAAA==/',
		_etag: '"0d00b389-0000-0d00-0000-663b61fc0000"',
		_attachments: 'attachments/',
		_ts: 1715167740,
	},
	{
		id: '663b5eda-9838-421d-979a-1b343f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'AUD',
			ccy: 'AUD',
			code: 'AUS200',
			decPrec: 2,
			displayName: 'AUS200',
			fullName: 'Austrailia 200 Index',
			feedId: 'VTFeed',
			flags: 4096,
			grp: 'Indices',
			isin: 'Indices',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'AUD',
			tickSize: 1,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 0,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
			marketItemInfo: {
				dataObjectType: 12,
				code: 'AUS200',
				decimalPrecision: '',
				feedId: 'VTFeed',
				formatLength: '2',
				formatPosition: '-2',
				pipSize: '',
				tickSize: '1',
			},
		},
		Report: {
			id: '663b5eda-9838-421d-979a-1b343f6e2587',
			group_id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			product_id: '5afec071-cedc-493a-9abd-438d3f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'Bitcoin',
			trigger: null,
			chart:
				'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587-resize-315x153-663b5ed99dcb5-small.png',
			pia_chart: 'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587-resize-1180x800-663b5ed90b749-small.png',
			pia_chart_590:
				'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587-resize-590x400-663b5ed955604-small.png',
			chart_scale:
				'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587-resize-315x153-663b5ed9b06eb-small.png',
			chart_large:
				'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587-resize-400x195-663b5ed973ad2-small.png',
			chart_scale_large:
				'charts/pia-chart/BTCUSD_2024-05-08_13-15-17663b5ed8-dd08-4889-b0d3-1b343f6e2587-resize-400x195-663b5ed9899ed-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 61650.0,
			sell_target_2: 61050.0,
			stop: 65150.0,
			res_1: 63000.0,
			res_2: 64300.0,
			res_3: 65428.0,
			sup_1: 62003.0,
			sup_2: 61000.0,
			sup_3: 60000.0,
			confidence: 90,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T11:07:54Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'TIV',
			created: '2024-05-08T12:07:54',
			modified: '2024-05-08T12:15:36',
			modifiedUtc: '2024-05-08T11:15:36Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 64150.0,
			locale: 'en_GB',
		},
		Group: {
			id: '5afebfc0-9d0c-48d5-9a7a-42ff3f6e2587',
			category_id: '5b3df421-18c8-485d-b013-29c33f6e2587',
			name: 'Cryptocurrencies',
			slug: 'cryptocurrencies',
			Category: {
				id: '5b3df421-18c8-485d-b013-29c33f6e2587',
				name: 'Cryptocurrencies',
				slug: 'cryptocurrencies',
			},
		},
		Product: {
			id: '5afec071-cedc-493a-9abd-438d3f6e2587',
			name: 'Bitcoin',
			slug: 'bitcoin-2',
			scale_factor: 1.0,
			market_id_name: '',
			tradable_name: 'BTCUSDT, btcusd',
			chart_widget: '',
			trading_view_symbol: 'COINBASE:BTCUSD',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 64150'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'The bearish engulfing candle on the 4 hour chart is negative for sentiment',
			'Daily signals are mildly bearish',
			'Previous resistance located at 64300',
			'Expect trading to remain mixed and volatile',
			'We look for a temporary move higher',
		],
		_rid: 'ZL9MAMXeixtHJ2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixtHJ2YAAAAAAA==/',
		_etag: '"0c001678-0000-0d00-0000-663b5ef00000"',
		_attachments: 'attachments/',
		_ts: 1715166960,
	},
	{
		id: '663b5b3c-f40c-412e-8680-10cf3f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'NZD',
			ccy: 'USD',
			code: 'NZDUSD',
			decPrec: 5,
			displayName: 'NZDUSD',
			fullName: 'New Zealand Dollar vs US Dollar',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'USD',
			tickSize: 0.00001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 0,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b5b3c-f40c-412e-8680-10cf3f6e2587',
			group_id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			product_id: '61b3864e-c14c-4329-b4ac-42443f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: '',
			trigger: null,
			chart: 'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587-resize-315x147-663b5b3b5a828-small.jpg',
			pia_chart: 'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587.jpg',
			pia_chart_small:
				'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587-resize-1180x800-663b5b3b20af9-small.jpg',
			pia_chart_590:
				'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587-resize-590x400-663b5b3b35089-small.jpg',
			chart_scale: 'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587-resize-315x147-663b5b3b659d5-small.jpg',
			chart_large: 'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587-resize-400x187-663b5b3b4276e-small.jpg',
			chart_scale_large:
				'charts/pia-chart/US2000663b5b3a-51c8-4a80-aaac-10cf3f6e2587-resize-400x187-663b5b3b4f6ca-small.jpg',
			direction: 0,
			action: 0,
			buy_target_1: 2077.0,
			buy_target_2: 2145.0,
			sell_target_1: 0.0,
			sell_target_2: 0.0,
			stop: 1998.0,
			res_1: 2145.0,
			res_2: 2220.0,
			res_3: 2305.0,
			sup_1: 2025.0,
			sup_2: 1950.0,
			sup_3: 1890.0,
			confidence: 90,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T10:55:15Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 16,
			original_status: 16,
			daily_free_trade_strategy: false,
			author: 'MOH',
			created: '2024-05-08T11:55:15',
			modified: '2024-05-08T12:00:10',
			modifiedUtc: '2024-05-08T11:00:10Z',
			direction_text: 'Bullish',
			action_text: 'BUY',
			status_text: 'Buy Limit',
			original_status_text: 'Buy Limit',
			buy_entry_target_1: 2020.0,
			sell_entry_target_1: null,
			locale: 'en_GB',
		},
		Group: {
			id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			category_id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
			name: 'US Indices',
			slug: 'us-indices',
			Category: {
				id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
				name: 'Indices',
				slug: 'indices',
			},
		},
		Product: {
			id: '61b3864e-c14c-4329-b4ac-42443f6e2587',
			name: 'US2000',
			slug: 'us2000-3',
			scale_factor: 1.0,
			market_id_name: '',
			tradable_name: '',
			chart_widget: '',
			trading_view_symbol: '',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: ['We look to Buy at 2020'],
		SellPhrase: null,
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Although the bulls are in control, the stalling positive momentum indicates a turnaround is possible',
			'Price action looks to be forming a top',
			'We are trading at overbought extremes',
			'A lower correction is expected',
			'Our short term bias remains positive',
			'Preferred trade is to buy on dips',
		],
		_rid: 'ZL9MAMXeixsbJ2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixsbJ2YAAAAAAA==/',
		_etag: '"0c00f048-0000-0d00-0000-663b5b4e0000"',
		_attachments: 'attachments/',
		_ts: 1715166030,
	},
	{
		id: '663b47c1-e3bc-4908-ad69-5ae33f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'NZD',
			ccy: 'JPY',
			code: 'NZDJPY',
			decPrec: 3,
			displayName: 'NZDJPY',
			fullName: 'New Zealand Dollar vs Japanese Yen',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'JPY',
			tickSize: 0.001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 0,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b47c1-e3bc-4908-ad69-5ae33f6e2587',
			group_id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			product_id: '534eb320-af38-41e4-90c6-10725c3c7954',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'US500',
			trigger: null,
			chart:
				'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587-resize-315x132-663b47c0a0d94-small.png',
			pia_chart: 'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587-resize-1180x800-663b47c0144c4-small.png',
			pia_chart_590:
				'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587-resize-590x400-663b47c05ca7e-small.png',
			chart_scale:
				'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587-resize-315x132-663b47c0b2616-small.png',
			chart_large:
				'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587-resize-400x167-663b47c07a853-small.png',
			chart_scale_large:
				'charts/pia-chart/SPX500USD_2024-05-08_10-36-37663b47bf-287c-412d-b5fb-5ae33f6e2587-resize-400x167-663b47c08e954-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 5071.0,
			sell_target_2: 5050.0,
			stop: 5242.0,
			res_1: 5208.0,
			res_2: 5241.0,
			res_3: 5284.0,
			sup_1: 5071.0,
			sup_2: 5011.0,
			sup_3: 4930.0,
			confidence: 90,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T09:36:49Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'Ian',
			created: '2024-05-08T10:36:49',
			modified: '2024-05-08T10:37:03',
			modifiedUtc: '2024-05-08T09:37:03Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 5207.0,
			locale: 'en_GB',
		},
		Group: {
			id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			category_id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
			name: 'US Indices',
			slug: 'us-indices',
			Category: {
				id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
				name: 'Indices',
				slug: 'indices',
			},
		},
		Product: {
			id: '534eb320-af38-41e4-90c6-10725c3c7954',
			name: 'US500',
			slug: 'us500-3',
			scale_factor: 1.0,
			market_id_name: 'US500',
			tradable_name: 'SPX500/SP_ecn',
			chart_widget: 'SP500',
			trading_view_symbol: 'spx500usd',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 5207'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Majority of the initial daily gains being overturned',
			'The AB=CD formation target is located at 5208',
			'We have a 78.6% Fibonacci pullback level of 5208 from  5284 to 4930',
			'Bespoke support is located at 5071',
			'Selling spikes offers good risk/reward',
		],
		_rid: 'ZL9MAMXeixvvJmYAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixvvJmYAAAAAAA==/',
		_etag: '"07003931-0000-0d00-0000-663b47da0000"',
		_attachments: 'attachments/',
		_ts: 1715161050,
	},
	{
		id: '663b4743-0c0c-458f-8929-59383f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'USD',
			ccy: 'USD',
			code: 'LTCUSD',
			decPrec: 2,
			displayName: 'LTCUSD',
			fullName: 'LiteCoin',
			feedId: 'VTFeed',
			flags: 262144,
			grp: 'Crypto',
			isin: 'Crypto',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'USD',
			tickSize: 0.01,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 1,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
			marketItemInfo: {
				dataObjectType: 12,
				code: 'LTCUSD',
				decimalPrecision: '1',
				feedId: 'VTFeed',
				formatLength: '3',
				formatPosition: '-1',
				pipSize: '',
				tickSize: '',
			},
		},
		Report: {
			id: '663b4743-0c0c-458f-8929-59383f6e2587',
			group_id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			product_id: '534eb30a-7ef0-42a2-b206-10725c3c7954',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'USTECH',
			trigger: null,
			chart:
				'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587-resize-315x132-663b4742bf56a-small.png',
			pia_chart: 'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587-resize-1180x800-663b4742290f2-small.png',
			pia_chart_590:
				'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587-resize-590x400-663b474274da4-small.png',
			chart_scale:
				'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587-resize-315x132-663b4742d32a6-small.png',
			chart_large:
				'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587-resize-400x167-663b474294c12-small.png',
			chart_scale_large:
				'charts/pia-chart/NAS100USD_2024-05-08_10-34-18663b4741-68b0-4809-97b1-59383f6e2587-resize-400x167-663b4742aafa9-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 17705.0,
			sell_target_2: 17600.0,
			stop: 18245.0,
			res_1: 18137.0,
			res_2: 18173.0,
			res_3: 18497.0,
			sup_1: 18807.0,
			sup_2: 17600.0,
			sup_3: 17300.0,
			confidence: 90,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T09:34:38Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'Ian',
			created: '2024-05-08T10:34:38',
			modified: '2024-05-08T10:34:57',
			modifiedUtc: '2024-05-08T09:34:57Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 18135.0,
			locale: 'en_GB',
		},
		Group: {
			id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			category_id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
			name: 'US Indices',
			slug: 'us-indices',
			Category: {
				id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
				name: 'Indices',
				slug: 'indices',
			},
		},
		Product: {
			id: '534eb30a-7ef0-42a2-b206-10725c3c7954',
			name: 'US100',
			slug: 'us100-1',
			scale_factor: 1.0,
			market_id_name: 'USTECH',
			tradable_name: 'NAS100',
			chart_widget: '',
			trading_view_symbol: 'nas100usd',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 18135'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Majority of the initial daily gains being overturned',
			'The AB=CD formation target is located at 18137',
			'We have a 78.6% Fibonacci pullback level of 18173 from  18497 to 16985',
			'Bespoke support is located at 17705',
			'Selling spikes offers good risk/reward',
		],
		_rid: 'ZL9MAMXeixvDJmYAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixvDJmYAAAAAAA==/',
		_etag: '"0600a27f-0000-0d00-0000-663b47440000"',
		_attachments: 'attachments/',
		_ts: 1715160900,
	},
	{
		id: '663b46d6-afc4-4de3-8f43-57a53f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'GBP',
			ccy: 'NZD',
			code: 'GBPNZD',
			decPrec: 5,
			displayName: 'GBPNZD',
			fullName: 'Great Britain Pound vs New Zealand Dollar',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'NZD',
			tickSize: 0.00001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 0,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b46d6-afc4-4de3-8f43-57a53f6e2587',
			group_id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			product_id: '534eb2fe-c9b4-4986-90d5-10725c3c7954',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: 'WALL',
			trigger: null,
			chart:
				'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587-resize-315x132-663b46d5673f3-small.png',
			pia_chart: 'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587-resize-1180x800-663b46d4d257f-small.png',
			pia_chart_590:
				'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587-resize-590x400-663b46d525253-small.png',
			chart_scale:
				'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587-resize-315x132-663b46d578597-small.png',
			chart_large:
				'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587-resize-400x167-663b46d5422df-small.png',
			chart_scale_large:
				'charts/pia-chart/US30USD_2024-05-08_10-32-59663b46d4-5afc-4920-86ea-57a53f6e2587-resize-400x167-663b46d555a4d-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 38435.0,
			sell_target_2: 38400.0,
			stop: 39110.0,
			res_1: 38966.0,
			res_2: 38972.0,
			res_3: 40000.0,
			sup_1: 38435.0,
			sup_2: 38400.0,
			sup_3: 37500.0,
			confidence: 90,
			expiry: '2024-05-09T11:00:00Z',
			live_time: '2024-05-08T09:31:53Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'Ian',
			created: '2024-05-08T10:31:53',
			modified: '2024-05-08T10:33:08',
			modifiedUtc: '2024-05-08T09:33:08Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 38960.0,
			locale: 'en_GB',
		},
		Group: {
			id: '5344feec-e950-40ca-9b48-263f5c3c7954',
			category_id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
			name: 'US Indices',
			slug: 'us-indices',
			Category: {
				id: '53747cd7-f830-46ba-afc8-06ad0a0a0a0a',
				name: 'Indices',
				slug: 'indices',
			},
		},
		Product: {
			id: '534eb2fe-c9b4-4986-90d5-10725c3c7954',
			name: 'US30',
			slug: 'us30-1',
			scale_factor: null,
			market_id_name: 'WALL',
			tradable_name: 'US30/DOW_ecn',
			chart_widget: '',
			trading_view_symbol: 'US30USD',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 38960'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Majority of the initial daily gains being overturned',
			'A move to 38972 will form an intraday bearish Gartley pattern',
			'We have a 61.8% Fibonacci pullback level of 38966 from  40031 to 37244',
			'Due to an Ending Wedge formation, we continue to treat extended gains with caution',
			'The formation has a measured move target of 38435',
		],
		_rid: 'ZL9MAMXeixuqJmYAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixuqJmYAAAAAAA==/',
		_etag: '"06007e1c-0000-0d00-0000-663b46ec0000"',
		_attachments: 'attachments/',
		_ts: 1715160812,
	},
	{
		id: '663b1dd2-027c-4db0-8206-6a603f6e25872',
		language: 'en-gb',
		marketItem: {
			bCcy: 'USD',
			ccy: 'CHF',
			code: 'USDCHF',
			decPrec: 5,
			displayName: 'USDCHF',
			fullName: 'US Dollar vs Swiss Franc',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'CHF',
			tickSize: 0.00001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 0,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b1dd2-027c-4db0-8206-6a603f6e25873',
			group_id: '53149292-9538-4545-bef1-049e0a0a0a0a',
			product_id: '5d286e96-896c-40df-b311-037e3f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: '',
			trigger: null,
			chart:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-315x144-663b1de59329d-small.png',
			pia_chart: 'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-1180x800-663b1de475032-small.png',
			pia_chart_590:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-590x400-663b1de4ee5c6-small.png',
			chart_scale:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-315x144-663b1de5c27e1-small.png',
			chart_large:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-400x183-663b1de534191-small.png',
			chart_scale_large:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-400x183-663b1de5652d8-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 1.7865,
			sell_target_2: 1.7835,
			stop: 1.804,
			res_1: 1.7935,
			res_2: 1.7952,
			res_3: 1.798,
			sup_1: 1.7875,
			sup_2: 1.7842,
			sup_3: 1.78,
			confidence: 80,
			expiry: '2024-05-09T05:00:00Z',
			live_time: '2024-05-08T06:40:58Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'TIV ',
			created: '2024-05-08T07:40:58',
			modified: '2024-05-08T07:38:28',
			modifiedUtc: '2024-05-08T06:38:28Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 1.7985,
			locale: 'en_GB',
		},
		Group: {
			id: '53149292-9538-4545-bef1-049e0a0a0a0a',
			category_id: '53747ccf-7860-443a-8876-04ac0a0a0a0a',
			name: 'FX Crosses',
			slug: 'fx-crosses',
			Category: {
				id: '53747ccf-7860-443a-8876-04ac0a0a0a0a',
				name: 'FX',
				slug: 'fx',
			},
		},
		Product: {
			id: '5d286e96-896c-40df-b311-037e3f6e2587',
			name: 'EURNZD',
			slug: 'eurnzd-3',
			scale_factor: 10000.0,
			market_id_name: '',
			tradable_name: 'EURNZD, EURNZD_ecn',
			chart_widget: 'EURNZD',
			trading_view_symbol: 'FX:EURNZD',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 1.7985'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Previous support at 1.7980 now becomes resistance',
			'Short term bias has turned negative',
			'The trend of higher intraday lows has also been broken',
			'We look for a temporary move higher',
			'Preferred trade is to sell into rallies',
		],
		_rid: 'ZL9MAMXeixs1D2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixs1D2YAAAAAAA==/',
		_etag: '"1900ca60-0000-0d00-0000-663b1e040000"',
		_attachments: 'attachments/',
		_ts: 1715150340,
	},
	{
		id: '663b1dd2-027c-4db0-8206-6a603f6e2587',
		language: 'en-gb',
		marketItem: {
			bCcy: 'AUD',
			ccy: 'USD',
			code: 'AUDUSD',
			decPrec: 5,
			displayName: 'AUDUSD',
			fullName: 'Australian Dollar vs US Dollar',
			feedId: 'VTFeed',
			flags: 256,
			grp: 'Forex',
			isin: 'Forex',
			marginType: -1,
			pipSizeH: '',
			qCcy: 'USD',
			tickSize: 0.00001,
			tradable: true,
			exchangeCountryCode: '',
			sector: '',
			exchangeTicker: '',
			minTier: 0,
			watchlistRank: 0,
			subscriptionCounter: 2,
			accountMarketType: 0,
			accountType: 0,
			dataObjectType: 4,
		},
		Report: {
			id: '663b1dd2-027c-4db0-8206-6a603f6e25871',
			group_id: '53149292-9538-4545-bef1-049e0a0a0a0a',
			product_id: '5d286e96-896c-40df-b311-037e3f6e2587',
			period_id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			market_id: '',
			trigger: null,
			chart:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-315x144-663b1de59329d-small.png',
			pia_chart: 'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587.png',
			pia_chart_small:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-1180x800-663b1de475032-small.png',
			pia_chart_590:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-590x400-663b1de4ee5c6-small.png',
			chart_scale:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-315x144-663b1de5c27e1-small.png',
			chart_large:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-400x183-663b1de534191-small.png',
			chart_scale_large:
				'charts/pia-chart/EURNZD_2024-05-08_08-37-58663b1de4-8cc0-4d0b-952a-6ab83f6e2587-resize-400x183-663b1de5652d8-small.png',
			direction: 1,
			action: 1,
			buy_target_1: 0.0,
			buy_target_2: 0.0,
			sell_target_1: 1.7865,
			sell_target_2: 1.7835,
			stop: 1.804,
			res_1: 1.7935,
			res_2: 1.7952,
			res_3: 1.798,
			sup_1: 1.7875,
			sup_2: 1.7842,
			sup_3: 1.78,
			confidence: 80,
			expiry: '2024-05-09T05:00:00Z',
			live_time: '2024-05-08T06:40:58Z',
			report_status: null,
			stop_update: 0.0,
			target_update: 0.0,
			status: 14,
			original_status: 14,
			daily_free_trade_strategy: false,
			author: 'TIV ',
			created: '2024-05-08T07:40:58',
			modified: '2024-05-08T07:38:28',
			modifiedUtc: '2024-05-08T06:38:28Z',
			direction_text: 'Bearish',
			action_text: 'SELL',
			status_text: 'Sell Limit',
			original_status_text: 'Sell Limit',
			buy_entry_target_1: null,
			sell_entry_target_1: 1.7985,
			locale: 'en_GB',
		},
		Group: {
			id: '53149292-9538-4545-bef1-049e0a0a0a0a',
			category_id: '53747ccf-7860-443a-8876-04ac0a0a0a0a',
			name: 'FX Crosses',
			slug: 'fx-crosses',
			Category: {
				id: '53747ccf-7860-443a-8876-04ac0a0a0a0a',
				name: 'FX',
				slug: 'fx',
			},
		},
		Product: {
			id: '5d286e96-896c-40df-b311-037e3f6e2587',
			name: 'EURNZD',
			slug: 'eurnzd-3',
			scale_factor: 10000.0,
			market_id_name: '',
			tradable_name: 'EURNZD, EURNZD_ecn',
			chart_widget: 'EURNZD',
			trading_view_symbol: 'FX:EURNZD',
			logo: null,
			description: '',
			icon: '',
		},
		Period: {
			id: '53149989-5570-4906-a7ac-04a10a0a0a0a',
			name: 'Intraday',
			slug: 'intraday',
		},
		BuyPhrase: null,
		SellPhrase: ['We look to Sell at 1.7985'],
		T1Phrase: null,
		T2Phrase: null,
		Phrase: [
			'Previous support at 1.7980 now becomes resistance',
			'Short term bias has turned negative',
			'The trend of higher intraday lows has also been broken',
			'We look for a temporary move higher',
			'Preferred trade is to sell into rallies',
		],
		_rid: 'ZL9MAMXeixs1D2YAAAAAAA==',
		_self: 'dbs/ZL9MAA==/colls/ZL9MAMXeixs=/docs/ZL9MAMXeixs1D2YAAAAAAA==/',
		_etag: '"1900ca60-0000-0d00-0000-663b1e040000"',
		_attachments: 'attachments/',
		_ts: 1715150340,
	},
];
