import { PriceQuote, PriceType, TimeScale, TradingAccount, TradingPosition } from '../../gateways/RfpGateway/rfp.types';
import RfpGateway, { IPriceFeed } from '../../gateways/RfpGateway/RfpGateway';

import { findCommonMarketItem, getTradingInstrument } from './calculations';
import { Resolver } from './Ioc';
import Lazy from './Lazy';
import { Optional } from './Nullable';

class TTSubscriptionManager {
	private static _instance = new Lazy(() => {
		return new TTSubscriptionManager();
	});

	public static get instance(): TTSubscriptionManager {
		return this._instance.getValue();
	}

	//private readonly currentPrices: Map<string, PriceQuote> = new Map()

	/**
	 * The Singleton's constructor should always be private to prevent direct
	 * construction calls with the `new` operator.
	 */
	private constructor() {}

	private priceFeed: IPriceFeed | undefined;

	// set rfpGateway(value: RfpGateway) {
	//     if (!this._rfpGateway) {
	//         this._rfpGateway = value

	//         this._rfpGateway.subscribe(RFP.queueQuotes).then((observable) => {
	//             //observable.filter
	//             const subscription = observable.subscribe((priceQuote) => {
	//                 //this._rfpGateway?.currentPrices
	//                 this.currentPrices.set(`${priceQuote.f}-${priceQuote.c}`, priceQuote)
	//                 console.log(priceQuote)
	//             })
	//             this.subscription = subscription
	//         })
	//     }
	// }

	public getHistoryRequestId(code: string, timescale: TimeScale, priceType: PriceType | undefined) {
		return `reqHS_${code}_${timescale}_${priceType}`;
	}

	public watchPriceQuote(
		feedId: string,
		code: string,
		_rfpGateway: Optional<RfpGateway> = undefined
	): PriceQuote | undefined {
		const rfpGateway = _rfpGateway ?? Resolver.resolve(RfpGateway);
		if (rfpGateway) {
			//console.log("watchSecurityInfo code=" + code)
			const priceQuote = rfpGateway.getQuotePrices(feedId, code);
			this.subscribeForPriceQuotes(rfpGateway, feedId, code);
			return priceQuote;
		}
	}

	public unwatchPriceQuote(feedId: string, code: string) {
		//console.log("unwatchSecurityInfo code=" + code)
		if (this.priceFeed) {
			this.priceFeed.unsubscribe({ feedId: feedId, code: code });
		}
	}

	private subscribeForPriceQuotes(rfpGateway: RfpGateway, feedId: string, code: string) {
		this.priceFeed = rfpGateway.createPriceFeed(feedId);
		if (this.priceFeed) {
			this.priceFeed.subscribe({ feedId: feedId, code: code });
		}
	}

	/**
	 * This will subscribe or unsubscribe all instruments required for calculations of the provided position
	 */
	public updatePositionSubscriptions(
		tradingAccount: TradingAccount,
		position: TradingPosition,
		subscribe: boolean,
		_rfpGateway: Optional<RfpGateway>
	) {
		const rfpGateway =
			_rfpGateway ?? (Resolver.isSet && Resolver.isRegistered(RfpGateway)) ? Resolver.resolve(RfpGateway) : undefined;
		if (rfpGateway) {
			if (subscribe) {
				this.watchPriceQuote(position.f, position.code, rfpGateway);
			} else {
				this.unwatchPriceQuote(position.f, position.code);
			}

			const baseCcyPos = position.marketItem?.qCcy;
			if (!baseCcyPos) {
				return;
			}

			let baseCcyAcct = tradingAccount.baseCurrency;
			if (!baseCcyAcct) {
				return;
			}

			let mt = rfpGateway.getMarketItem(position.code, position.f);
			if (mt === undefined) {
				return;
			}

			if (baseCcyPos !== baseCcyAcct) {
				let pair = baseCcyPos + baseCcyAcct;

				let info = getTradingInstrument(tradingAccount, pair);
				if (!info) {
					pair = baseCcyAcct + baseCcyPos;
					info = getTradingInstrument(tradingAccount, pair);
				}

				if (!info) {
					pair = baseCcyAcct + mt.bCcy;

					info = getTradingInstrument(tradingAccount, pair);
					if (!info) {
						pair = mt.bCcy + baseCcyAcct;
						info = getTradingInstrument(tradingAccount, pair);
					}
				}

				if (!info) {
					// Find cross symbol
					let mtPair: any = findCommonMarketItem(baseCcyPos, baseCcyAcct);
					if (mtPair) {
						pair = mtPair.first.code;

						if (pair) {
							if (subscribe) {
								position.additionalSubscriptionPair.add(pair);
								this.watchPriceQuote(position.f, pair, rfpGateway);
							} else {
								position.additionalSubscriptionPair.delete(pair);
								this.unwatchPriceQuote(position.f, pair);
							}
						}
						info = getTradingInstrument(tradingAccount, mtPair.second.code);
					} else {
						return;
					}
				}

				if (info) {
					pair = info.code;

					if (subscribe) {
						position.additionalSubscriptionPair.add(pair);
						this.watchPriceQuote(position.f, pair, rfpGateway);
					} else {
						position.additionalSubscriptionPair.delete(pair);
						this.unwatchPriceQuote(position.f, pair);
					}
				}
			}
		} else {
			console.log('Missing context');
		}
	}
}

export default TTSubscriptionManager;
