import React, { useContext, useMemo, useState } from 'react';

import cn from 'classnames';

import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
	formatNumberWithCommas,
	formattedNumber,
} from '../../features/Dashboard/Watchlist/Instrument/formattedQuoteNumber';
import {
	calculateInitialStopLossValue,
	calculateInitialTakeProfitValue,
	getFormattedSpreadValue,
	isWorkingTime,
} from '../../../utils/functions/calculations';
import { InitialStopLossTakeProfitValues } from '../../features/Dashboard/ChartPanel/NewOrderModals/OrderTicketModal/OrderTicketModal';
import { MarketItem, PriceQuote } from '../../../gateways/RfpGateway/rfp.types';

import { default as AppContext } from '../../../contexts/AppContext';
import { default as DashboardContext } from '../../../contexts/DashboardContext';

import orderStore from '../../../store/OrderStore/orderStore';
import { default as usePromiseFactory } from '../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';
import { default as Nullable } from '../../../utils/functions/Nullable';
import { TicketLayout, TradingPositionSide } from '../../../utils/functions/enums';
import uniqueId from '../../../utils/functions/uniqueId';
import useShortTranslation from '../../../utils/hooks/useShortTranslation';
import useHandleOrderConstraint from '../../../utils/hooks/useHandleOrderConstraint';
import useHandleSubscriptionConstraints from '../../../utils/hooks/useHandleSubscriptionConstraints';

import { getGeneralFormatDate } from '../../../utils/functions/subscriptionUtils';

import authStore from '../../../store/authStore';

import tradingAccountStore from '../../../store/tradingAccountStore';

import Tooltip from './../Tooltip/Tooltip';

import styles from './BidAskSpread.module.scss';
import tradingViewStore from '../../../store/tradingViewStore';
import accountStatusStore, { StatusStore } from '../../../store/accountStatusStore';

interface BidAskSpreadProps {
	marketItem: MarketItem;
	order?: boolean;
	typeOfOrder?: string | undefined;
	stopLoss?: boolean;
	takeProfit?: boolean;
	tradeMeasure?: string;
	profitTradeMeasure?: string;
	dispatcher?: any;
	limitPrice?: string;
	isBidAskDisabled?: boolean;
	gridChartMenu?: boolean;
	instrument?: PriceQuote | null;
	id?: any;
	onChartClick?: () => void;
	showPriceStats?: boolean;
	spreadPosition?: boolean;
	resizeButton?: boolean;
	showButtonText?: boolean;
	headerSpreadPosition?: boolean;
	ticketLayout: string | null;
}

const BidAskSpread = React.memo<BidAskSpreadProps>(
	({
		marketItem,
		order,
		typeOfOrder,
		stopLoss,
		takeProfit,
		tradeMeasure,
		profitTradeMeasure,
		dispatcher,
		limitPrice,
		isBidAskDisabled,
		gridChartMenu,
		instrument,
		onChartClick,
		showPriceStats,
		spreadPosition,
		resizeButton,
		showButtonText,
		headerSpreadPosition,
		ticketLayout,
	}) => {
		const appContext = useContext(AppContext);
		const dashboardContext = useContext(DashboardContext);

		//is we use `use.current().side` every change in the order will trigger a re-render; this is more optimal
		const tradingPositionSide = orderStore((state) => state.current.side);
		//using OrderStore callback because use break typescript
		const storeMarketItem = orderStore((state) => state.marketItem);
		const setTradeProps = orderStore.use.setTradeProps();
		const resetOrderStore = orderStore.use.reset();
		const setTradingPosition = orderStore.use.setTradingPosition();
		const setMarketItem = orderStore.use.setMarketItem();
		const isLiveMode = authStore.use.isLiveMode();

		const forceRerender = useForceRerender();
		const promiseFactory = usePromiseFactory();
		const { t } = useTranslation();
		const tt = useShortTranslation('en:');

		const isChildWindow = appContext.isChildWindow;
		const languageSettings = appContext.languageSettings;
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

		const selectedInstrument = dashboardContext.selectedInstrument;
		const setTicketLayout = tradingViewStore.use.setTicketLayout();
		const modifyTicket = dashboardContext.modifyTicket;
		const tradingAccount = dashboardContext.tradingAccount;
		const selectedPosition = dashboardContext.selectedPosition;
		const selectedType = dashboardContext.selectedType;
		const handleSubscriptionsConstraints = useHandleSubscriptionConstraints();
		const handleOrderConstraint = useHandleOrderConstraint();

		const [prevBid, setPrevBid] = useState<number>(0);
		const [previousBid, setPreviousBid] = useState(0);
		const [prevAsk, setPrevAsk] = useState<number>(0);
		const [previousAsk, setPreviousAsk] = useState(0);
		const [selectedQuantity] = useState<string | undefined>(undefined);

		const isSelected = selectedPosition && selectedPosition[0] ? selectedPosition[0] : '';
		const isInstrument = selectedInstrument && marketItem ? selectedInstrument?.code === marketItem?.code : false;
		const isGrid = isSelected.code === marketItem?.code;
		//  Maximum length for short high/low strings used to apply different styling for longer strings
		const maxHighLowStringsLength = 12;
		const displayPriceA = instrument ? instrument.a : 0;
		const displayPriceB = instrument ? instrument.b : 0;
		const instrumentZero: string = Number(0).toFixed(marketItem?.decPrec);
		const INITIAL_VALUE: number = 0.25;
		const INITIAL_TAKEPROFIT_VALUE = 30;
		const getPathname = window.location.pathname === '/web-trader/watchlist/ticker';
		const tradeButtons = accountStatusStore((state: StatusStore) => (state.permissions || {}).tradeButtons);
		const setShowModal = accountStatusStore((state: StatusStore) => state.setShowModal);

		const bidNumber = useMemo(
			() =>
				formattedNumber(
					instrument ? instrument.b : instrumentZero,
					marketItem,
					isSpreadBettingAccount,
					order,
					gridChartMenu,
					languageSettings,
					undefined
				),
			[instrument, marketItem, order, instrumentZero, gridChartMenu]
		);
		const askNumber = useMemo(
			() =>
				formattedNumber(
					instrument ? instrument.a : instrumentZero,
					marketItem,
					isSpreadBettingAccount,
					order,
					gridChartMenu,
					languageSettings,
					undefined
				),
			[instrument, marketItem, order, instrumentZero, gridChartMenu]
		);

		const highNumber = useMemo(
			() => formatNumberWithCommas(instrument ? instrument.h : +instrumentZero, marketItem?.decPrec, languageSettings),
			[marketItem, instrument, instrumentZero]
		);
		const lowNumber = useMemo(
			() => formatNumberWithCommas(instrument ? instrument.l : +instrumentZero, marketItem?.decPrec, languageSettings),
			[marketItem, instrument, instrumentZero]
		);

		const timeUpdate = useMemo(
			() => instrument && getGeneralFormatDate(moment(instrument.t).toDate(), true, appContext.isJapanAccount),
			[instrument, marketItem]
		);

		const instrumentColor = (left: number, right: number) => {
			return {
				[styles.instrumentGrey]: left === 0 && right === 0,
				[styles.instrumentRed]: left <= right,
				[styles.instrumentGreen]: left >= right,
			};
		};

		useObservable(appContext.getPropertyChangeStream('isChildWindow'), async () => {
			await promiseFactory.throttle('appContext.propertyChanged', 100);
			forceRerender();
		});

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'detailedInformation',
				'selectedInstrument',
				'quantityType',
				'orderModalOpenedFrom',
				'modifyTicket',
				'tradingAccount',
				'selectedPosition',
				'selectedType',
				'applicationStatus',
				'accountValues'
			),
			async () => {
				await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
				forceRerender();
			}
		);

		Nullable.of(instrument).run((instrument) => {
			if (previousBid !== instrument.b) {
				setPreviousBid(instrument.b);
			}
			if (previousAsk !== instrument.a) {
				setPreviousAsk(instrument.a);
			}
			if (previousBid !== instrument.b) {
				setPrevBid(previousBid);
			}
			if (previousAsk !== instrument.a) {
				setPrevAsk(previousAsk);
			}
		});

		const buttonStyles = (left: number, right: number) => {
			if (resizeButton && !gridChartMenu) {
				return cn(styles.resizedButton, order ? styles.orderButton : '', instrumentColor(left, right));
			}
			return cn(
				!order
					? !getPathname
						? gridChartMenu
							? styles.gridChartInstrumentButton
							: styles.instrumentButton
						: styles.windowInstrumentButton
					: styles.orderButton,
				instrumentColor(left, right),
				'bidAskSpreadButton' // be able to address the buttons in the current instrument
			);
		};

		const orderButtonStyles = (left: number, right: number, button: any) => {
			return !button
				? cn(ticketLayout === TicketLayout.Dock ? styles.orderButton : styles.orderButtonModal, styles.borderButton, {
						[styles.orderGrey]: left === 0 && right === 0,
						[styles.orderRed]: left <= right,
						[styles.orderGreen]: left >= right,
				  })
				: cn(
						ticketLayout === TicketLayout.Dock ? styles.orderButton : styles.orderButtonModal,
						instrumentColor(left, right)
				  );
		};

		const spreadIndicatorPosition = () => {
			if (spreadPosition && !getPathname) {
				return styles.rightHandSpread;
			} else if (headerSpreadPosition && !getPathname) {
				return styles.headerTickerButtons;
			} else if (!getPathname) {
				return styles.instrumentPosition;
			} else {
				return styles.windowInstrumentPosition;
			}
		};

		const spreadButtonStyles = () => {
			return cn(
				ticketLayout === TicketLayout.Dock ? styles.orderPosition : styles.orderPositionModal,
				styles.spreadButton
			);
		};

		const displayHighlight = () => {
			return isInstrument && selectedType === 'Instrument'
				? styles.highlightSpread
				: isGrid && selectedType === 'Grid'
				? styles.highlightSpread
				: styles.spread;
		};

		const handleButtonClick = (side: TradingPositionSide) => {
			if (!instrument) {
				return;
			}

			if (tradeButtons === 'accountStatus') {
				setShowModal(true);
				appContext.statusModal = true;
				return false;
			}

			// Want to one-click-trade? Not so fast!
			// Is this a Japan account with some sort of constraint?
			if (isJapanSubscriptionAccount && isLiveMode) {
				const nonVolumeConstraint = handleSubscriptionsConstraints(marketItem);
				if (nonVolumeConstraint) {
					return;
				}

				const orderSizeConstraint = handleOrderConstraint(parseInt(selectedQuantity!));
				if (orderSizeConstraint) {
					return;
				}
			}

			onChartClick && onChartClick();
			dashboardContext.modifyTicket = false;
			dashboardContext.showConfirmTicket = false;

			// Open up Order modal
			dashboardContext.orderModalOpenedFrom = 'watchlist';
			// dashboardContext.orderType = orderType;

			dashboardContext.selectedInstrument = marketItem;
			dashboardContext.isEdit = false;

			if (tradingAccount.length > 0) {
				if (storeMarketItem && storeMarketItem.code !== marketItem.code) {
					resetOrderStore();
				}

				setTradingPosition(undefined);
				setMarketItem(undefined);
				setTradeProps({ side: side });

				if (ticketLayout === TicketLayout.Dock || !ticketLayout) {
					setTicketLayout(TicketLayout.Dock);
					dashboardContext.showOrderTicket = true;
					dashboardContext.showNewsFeed = false;
					dashboardContext.showOrderInformation = false;
					dashboardContext.showCloseTicket = false;
					dashboardContext.showCancelTicket = false;
				} else {
					dashboardContext.newOrderModalToggle = {
						orderTicket: true,
						confirmOrder: false,
					};
					dashboardContext.showOrderTicket = false;
				}
			}
		};

		/* Clicking on Trade side Buy or Sell should set Initial values to  Stoploss and take profit if the checkbox is enabled*/
		const handleTradeSideClick = (value: TradingPositionSide) => {
			if (!isBidAskDisabled) {
				/* setTradingPositionSide(value); */
				setTradeProps({ side: value });

				const initialStopLossTakeProfitObj: InitialStopLossTakeProfitValues = {
					instrument:
						value === TradingPositionSide.Buy ? (instrument ? instrument.a : 0) : instrument ? instrument.b : 0,
					initialValue: INITIAL_VALUE,
					side: tradingPositionSide,
					decimalPrecision: selectedInstrument?.decPrec ?? 0,
					typeOfOrder: typeOfOrder!,
					limitPrice: typeOfOrder === 'stop' || typeOfOrder === 'limit' ? limitPrice! : '0',
				};

				if (value === TradingPositionSide.Sell && stopLoss) {
					dispatcher({
						type: 'setStopLossValues',
						payload:
							tradeMeasure === 'stopLoss_Price'
								? calculateInitialStopLossValue(initialStopLossTakeProfitObj)
								: INITIAL_TAKEPROFIT_VALUE,
					});
				}
				if (value === TradingPositionSide.Buy && stopLoss) {
					dispatcher({
						type: 'setStopLossValues',
						payload:
							tradeMeasure === 'stopLoss_Price'
								? calculateInitialStopLossValue(initialStopLossTakeProfitObj)
								: INITIAL_TAKEPROFIT_VALUE,
					});
				}

				if (value === TradingPositionSide.Sell && takeProfit) {
					dispatcher({
						type: 'setProfitValues',
						payload:
							profitTradeMeasure === 'profit_Price'
								? calculateInitialTakeProfitValue(initialStopLossTakeProfitObj)
								: INITIAL_TAKEPROFIT_VALUE,
					});
				}
				if (value === TradingPositionSide.Buy && takeProfit) {
					dispatcher({
						type: 'setProfitValues',
						payload:
							profitTradeMeasure === 'profit_Price'
								? calculateInitialTakeProfitValue(initialStopLossTakeProfitObj)
								: INITIAL_TAKEPROFIT_VALUE,
					});
				}
			}
		};

		const spreadButton = (side: TradingPositionSide) => {
			return (
				<>
					{order && (
						<div className={styles.checkboxPrice}>
							<label className={styles.container}>
								<input type="checkbox" checked={side === tradingPositionSide} onChange={() => {}}></input>
								<span className={styles.checkbox}></span>
							</label>
							<div className={styles.sellContainer}>
								<label className={styles.sellLabel}>
									{side === TradingPositionSide.Buy ? tt('BUY_ACTION') : tt('SELL_ACTION')}
								</label>
								<div className={styles.sellNumber}>{side === TradingPositionSide.Buy ? askNumber : bidNumber}</div>
							</div>
						</div>
					)}
					<div className={styles.showPrice}>{side === TradingPositionSide.Buy ? askNumber : bidNumber}</div>
				</>
			);
		};

		const buttonToolTip = (side: TradingPositionSide) => {
			const isOpenMarket = appContext.isJapanAccount ? undefined : isWorkingTime(marketItem, new Date());

			return (
				<div className={styles.tooltipTimestamp}>
					<div className={styles.tooltipTitle}>
						{tt(`${side}_ACTION`)}{' '}
						{marketItem?.exchangeTicker && marketItem?.exchangeTicker !== ''
							? marketItem?.exchangeTicker
							: marketItem?.displayName}
						{isOpenMarket !== undefined && !isOpenMarket && (
							<div className={cn(styles.closedMarketContainer)}>
								&#x2022;
								<div className={cn(styles.closedMarket)}>{t('en:202')}</div>
							</div>
						)}
					</div>

					{isOpenMarket && <div className={styles.timeUpdate}>{timeUpdate}</div>}
				</div>
			);
		};

		return (
			<div className={!order ? styles.notOrder : ''}>
				<div
					className={!order ? styles.tickerActions : modifyTicket ? styles.tickerActionsModify : styles.tickerActions}
				>
					<OverlayTrigger
						delay={{ show: 0, hide: 1 }}
						key={`sellButton${marketItem?.code}${Math.random()}`}
						placement="bottom"
						overlay={
							<Tooltip className="my-tooltip" id={`sellButton${marketItem?.code}${Math.random()}`}>
								{buttonToolTip(TradingPositionSide.Sell)}
							</Tooltip>
						}
					>
						<div
							className={styles.tickerContainer}
							key={uniqueId()}
							onClick={() => {
								!order ? handleButtonClick(TradingPositionSide.Sell) : handleTradeSideClick(TradingPositionSide.Sell);
							}}
						>
							<div
								className={
									!order
										? buttonStyles(displayPriceB, prevBid)
										: cn(orderButtonStyles(displayPriceB, prevBid, tradingPositionSide === TradingPositionSide.Sell))
								}
								data-testid="bidButton"
							>
								{showButtonText && <div className={styles.label}>{tt('SELL_ACTION')}</div>}
								{spreadButton(TradingPositionSide.Sell)}
							</div>
							{!order && !gridChartMenu && showPriceStats && (
								<div
									className={
										lowNumber.length + tt('LOW').length > maxHighLowStringsLength
											? styles.tickerLowLong
											: styles.tickerLow
									}
								>
									{tt('LOW')}{' '}
									<span className={styles.colorRed}>
										{isNaN(+lowNumber?.replaceAll(',', '')) ? t('wtr:NA') : lowNumber}
									</span>
								</div>
							)}
						</div>
					</OverlayTrigger>

					<div
						className={cn(
							styles.spread,
							!order
								? cn(!getPathname ? displayHighlight() : styles.windowSpread, spreadIndicatorPosition())
								: spreadButtonStyles()
						)}
					>
						{getFormattedSpreadValue(marketItem, displayPriceA, displayPriceB, languageSettings)}
					</div>

					<OverlayTrigger
						delay={{ show: 0, hide: 1 }}
						key={`buyButton${marketItem?.code}${Math.random()}`}
						placement="bottom"
						overlay={
							<Tooltip className="my-tooltip" id={`buyButton${marketItem?.code}${Math.random()}`}>
								{buttonToolTip(TradingPositionSide.Buy)}
							</Tooltip>
						}
					>
						<div
							className={styles.tickerContainer}
							key={uniqueId()}
							onClick={() =>
								!order ? handleButtonClick(TradingPositionSide.Buy) : handleTradeSideClick(TradingPositionSide.Buy)
							}
						>
							<div
								className={
									!order
										? buttonStyles(displayPriceA, prevAsk)
										: cn(orderButtonStyles(displayPriceA, prevAsk, tradingPositionSide === TradingPositionSide.Buy))
								}
								data-testid="askNumber"
							>
								{showButtonText && <div className={styles.label}>{tt('BUY_ACTION')}</div>}
								{spreadButton(TradingPositionSide.Buy)}
							</div>
							{!order && !gridChartMenu && showPriceStats && (
								<div
									className={
										highNumber.length + tt('LOW').length > maxHighLowStringsLength
											? styles.tickerHighLong
											: styles.tickerHigh
									}
								>
									{tt('HIGH')}{' '}
									<span className={styles.colorGreen}>
										{isNaN(+highNumber?.replaceAll(',', '')) ? t('wtr:NA') : highNumber}
									</span>
								</div>
							)}
						</div>
					</OverlayTrigger>
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps === nextProps ||
			(prevProps &&
				nextProps &&
				(
					[
						'marketItem',
						'order',
						'typeOfOrder',
						'stopLoss',
						'takeProfit',
						'tradeMeasure',
						'profitTradeMeasure',
						'dispatcher',
						'limitPrice',
						'isBidAskDisabled',
						'gridChartMenu',
						'instrument',
						'ticketLayout',
					] as Array<keyof typeof prevProps>
				).every((key) => prevProps[key] === nextProps[key]))
		);
	}
);

export default BidAskSpread;
