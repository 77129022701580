import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useGetPhoneCodesAndFlags from '../../../../../../../utils/mutations/useGetPhoneCodesAndFlags';

import styles from './PhoneCodeDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import authStore from '../../../../../../../store/authStore';
interface CountryPhoneCode {
	id: number;
	name: string;
	code2: string;
	code3: string;
	phoneCode: number;
	emoji?: string;
	flagUrl: string;
}

interface PhoneCodeDropdownProps {
	code?: string | number;
	handleSavePhoneCode: (phoneCodeType: string, phoneCode: string) => void;
	data: any;
	isLoadingFlagsAndCodes: boolean;
	isLoadingSaveData: boolean;
	isError: any;
	phoneCodeType: string;
}

const PhoneCodeDropdown: React.FC<PhoneCodeDropdownProps> = ({
	code,
	handleSavePhoneCode,
	data,
	isLoadingFlagsAndCodes,
	isLoadingSaveData,
	isError,
	phoneCodeType,
}) => {
	const [selectedCode, setSelectedCode] = useState<CountryPhoneCode | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const handleSelect = (dialCode: number) => {
		const selectedPhone = data?.result.find((phone: any) => phone.phoneCode === dialCode);
		if (selectedPhone) {
			setSelectedCode(selectedPhone);
			setIsOpen(false);
			handleSavePhoneCode(phoneCodeType, selectedPhone.phoneCode);
		}
	};

	useEffect(() => {
		if (code && data?.result) {
			handleSelect(Number(code));
		}
	}, [code, data]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	if (isLoadingFlagsAndCodes || isLoadingSaveData) {
		return <div className={styles.isLoading}>{t('en:LOADING')}</div>;
	}

	if (isError) {
		return <div className={styles.isError}>{isError.message}</div>;
	}
	return (
		<div className={styles.dropdown} ref={dropdownRef}>
			<div className={styles.dropdownHeader} onClick={() => setIsOpen(!isOpen)}>
				<div className={styles.selected}>
					<img src={selectedCode?.flagUrl} alt={selectedCode?.name} className={styles.flagImage} />
					<span>+{selectedCode?.phoneCode}</span>
				</div>
				<div>
					<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
				</div>
			</div>
			{isOpen && (
				<div className={styles.dropdownList}>
					{data.result
						.sort((a: any, b: any) => {
							return a.phoneCode - b.phoneCode;
						})
						.map((phoneCode: any) => (
							<div className={styles.dropdownItem} key={phoneCode.id} onClick={() => handleSelect(phoneCode.phoneCode)}>
								<img
									src={phoneCode.flagUrl}
									alt={phoneCode.name}
									className={styles.flagImage}
									onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
										e.currentTarget.src =
											'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png';
									}}
								/>
								<span className={styles.dialCode}>+{phoneCode.phoneCode}</span>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default PhoneCodeDropdown;
