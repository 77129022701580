import React, { useContext, useEffect, useMemo, useRef } from 'react';
import AppContext from '../../../../../contexts/AppContext';
import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import quoteStore from '../../../../../store/QuoteStore/quoteStore';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import { DEFAULT_FEED_ID } from '../../../../../utils/functions/WatchlistUtils';

const useDetachWatchList = () => {
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const dashboardContext = useContext(DashboardContext);

	const mappedWatchlist = dashboardContext.mappedWatchlist;

	const setQuote = quoteStore.use.setQuote();
	const currentWatchList = tradingAccountStore.use.currentWatchList();

	const priceFeed = useRef<any>();
	const subIdRef = useRef<string>();

	const unsubscribeInstruments = () => {
		if (rfpGatewayContext && priceFeed.current) {
			rfpGatewayContext.unsubscribePriceQuote(priceFeed.current);
			priceFeed.current = undefined;
		}
	};

	const tableData = useMemo(() => {
		const items: any[] = [];
		const codes = [];

		if (mappedWatchlist) {
			const watchListCodes = mappedWatchlist[currentWatchList];
			for (let code of watchListCodes) {
				const symbol = code._code;
				const marketItem = rfpGatewayContext?.getMarketItem(symbol);

				if (marketItem) {
					const obj = {
						symbol,
						name: marketItem.fullName,
						// change: changeMap[marketItem.code] || 0,
						low: marketItem.code,
						high: marketItem.code,
						sell: marketItem.code,
						buy: marketItem.code,
					};
					items.push(obj);
					codes.push(symbol);
				}
			}
		}

		return { items, codes };
	}, [mappedWatchlist, currentWatchList]);

	const subscribeToNewInstruments = (codes: string[]) => {
		if (rfpGatewayContext && tableData.items.length > 0 && tableData.codes.length > 0) {
			subIdRef.current = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, codes, (priceQuote) => {
				setQuote(priceQuote);
			});
		}
	};

	useEffect(() => {
		if (subIdRef.current) {
			unsubscribeInstruments();
		}

		subscribeToNewInstruments(tableData.codes);

		return () => {
			unsubscribeInstruments();
		};
	}, [tableData]);

	return tableData;
};

export default useDetachWatchList;
