import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import InstrumentContext from '../../../../../contexts/InstrumentContext';
import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import useOrderTicketAccess from '../../../../../utils/hooks/useOrderTicketAccess';
import { useMarketItemsMap } from '../../../../components/MarketItemFormatter/useMarketItemsMap';
import positionsStore from '../../../../../store/PositionsStore/positionsStore';
import {
	ColumnResizeDirection,
	ColumnResizeMode,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useVirtualizer } from '@tanstack/react-virtual';
import cn from 'classnames';
import styles from '../../../Markets/MarketsGridNew/MarketsTable.module.scss';
import SettingGrid from '../../PositionsPanel/Grids/components/SettingsGrid';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import DraggableTableHeader from '../../../Markets/MarketsGridNew/components/DraggableTableHeader';
import { PositionTableItem } from '../../../../../utils/functions/marketItems/marketItemGroupMapFormatter';
import DragAlongCell from '../../../Markets/MarketsGridNew/components/DragAlongCell';
import useDetachWatchListColumn from './useDetachWatchListColumn';
import useDetachWatchList from './useDetachWatchList';
import { AppComponentType } from '../../../../../utils/functions/enums';

const DetachWatchList = () => {
	const instrumentContext = useContext(InstrumentContext);
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const orderTicketAccess = useOrderTicketAccess();
	const marketsItemMap = useMarketItemsMap();

	const gridHeight = positionsStore.use.gridHeight();
	const positionPanelHeight = positionsStore.use.positionPanelHeight();

	const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
	const [columnResizeMode, _] = useState<ColumnResizeMode>('onChange');
	const [columnVisibility, setColumnVisibility] = useState({});
	const [globalFilter, setGlobalFilter] = React.useState('');
	const [highlightPosition, setHighlightPosition] = useState('');
	const [expanded, setExpanded] = React.useState({});
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	const tableContainerRef = React.useRef<HTMLDivElement>(null);
	const columns = useDetachWatchListColumn();
	const tableData = useDetachWatchList();
	const [sorting, setSorting] = useState<SortingState>([]);

	const [columnOrder, setColumnOrder] = React.useState<string[]>([]);

	useEffect(() => {
		const localDataOrder = localStorage.getItem('detachWatchListColumnsOrder');

		if (!columnOrder.length && localDataOrder?.length) {
			const parsedData = JSON.parse(localDataOrder);
			if (parsedData) {
				setColumnOrder(() => columns.map((c) => c.id!));
				localStorage.setItem('detachWatchListColumnsOrder', JSON.stringify(columns.map((c) => c.id!)));
			}
		} else if (!columnOrder.length && !localDataOrder?.length) {
			setColumnOrder(() => columns.map((c) => c.id!));
		} else {
			localStorage.setItem('detachWatchListColumnsOrder', JSON.stringify(columnOrder));
		}
	}, [columnOrder]);

	const columnResizeDirection: ColumnResizeDirection = useMemo(() => {
		return appContext.isArabic ? 'rtl' : 'ltr';
	}, [appContext.isArabic]);

	const table = useReactTable({
		data: tableData.items,
		columns,
		columnResizeMode,
		columnResizeDirection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		state: {
			sorting,
			columnVisibility,
			columnOrder,
			expanded,
			globalFilter,
		},
		onExpandedChange: setExpanded,
		onColumnVisibilityChange: setColumnVisibility,
		getFilteredRowModel: getFilteredRowModel(),
		onGlobalFilterChange: setGlobalFilter,
		onColumnOrderChange: setColumnOrder,
		getExpandedRowModel: getExpandedRowModel(),
		debugTable: true,
	});

	useEffect(() => {
		const localSortingData = localStorage.getItem('detachWatchListSortingTable');

		if (!localSortingData) {
			localStorage.setItem('detachWatchListSortingTable', JSON.stringify(sorting));
		} else {
			const data = JSON.parse(localSortingData);
			if (data) {
				setSorting(data);
			}
		}

		const setItemToStorage = () => {
			localStorage.setItem('detachWatchListSortingTable', JSON.stringify(table.getState().sorting));
		};

		window.addEventListener('beforeunload', setItemToStorage);

		return () => {
			setItemToStorage();
			window.removeEventListener('beforeunload', setItemToStorage);
		};
	}, []);

	const closeAllTickets = () => {
		dashboardContext.showOrderTicket = false;
		dashboardContext.showCloseTicket = false;
		dashboardContext.showCancelTicket = false;
		dashboardContext.modifyTicket = false;
		dashboardContext.showNewsFeed = false;
		dashboardContext.showConfirmTicket = false;
		dashboardContext.showOrderInformation = false;
	};

	const handleSelectedInstrument = (cell: any) => {
		if (!orderTicketAccess()) {
			return;
		}

		const record = marketsItemMap[cell.row.original.symbol];

		if (!record) {
			return;
		}

		const marketItem = instrumentContext.instruments.find(
			(instrument) => instrument.feedId === record.feedId && instrument.code === record.code
		);

		if (!marketItem) {
			return;
		}

		dashboardContext.presentComponentType = AppComponentType.Watchlist;

		setTimeout(() => {
			closeAllTickets();
			dashboardContext.showOrderTicket = false;
			dashboardContext.symbolChanged = marketItem.code;
			dashboardContext.gridChartsChanged = true;
			dashboardContext.selectedPosition = null;
			dashboardContext.selectedInstrument = marketItem;
			dashboardContext.selectedType = 'Instrument';

			if (cell.column.id === 'sell' || cell.column.id === 'buy') {
				// Open up Order Ticket
				dashboardContext.modifyTicket = false;
				dashboardContext.showConfirmTicket = false;

				dashboardContext.orderModalOpenedFrom = 'watchlist';

				// const tradingPositionSide = cell.column.id === 'sell' ? TradingPositionSide.Sell : TradingPositionSide.Buy;

				// setTradingPosition(undefined);
				// setMarketItem(marketItem);
				// setTradeProps({ side: tradingPositionSide });

				dashboardContext.isEdit = false;

				dashboardContext.showOrderTicket = true;
				dashboardContext.showNewsFeed = false;
				dashboardContext.showCloseTicket = false;
				dashboardContext.showCancelTicket = false;
			} else {
				dashboardContext.showOrderInformation = true;
				dashboardContext.toggleAccordionMenu = '0';
			}
		}, 500);
	};

	// reorder columns after drag & drop
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;
		if (active && over && active.id !== over.id) {
			setColumnOrder((columnOrder) => {
				const oldIndex = columnOrder.indexOf(active.id as string);
				const newIndex = columnOrder.indexOf(over.id as string);
				return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
			});
		}
	}

	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	const { rows } = table.getRowModel();

	//dynamic row height virtualization - alternatively you could use a simpler fixed row height strategy without the need for `measureElement`
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: useCallback(() => 40, []), //estimate row height for accurate scrollbar dragging
		getScrollElement: () => tableContainerRef.current,
		//measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
		overscan: 3,
	});

	const virtualRows = rowVirtualizer.getVirtualItems();

	return (
		<div className={cn(styles.positionPageTableWrapper)}>
			<DndContext
				collisionDetection={closestCenter}
				modifiers={[restrictToHorizontalAxis]}
				onDragEnd={handleDragEnd}
				sensors={sensors}
			>
				<div style={{ direction: table.options.columnResizeDirection }}>
					<div
						ref={tableContainerRef}
						className={cn(styles.tableContainerVirtualStyles)}
						style={
							rowVirtualizer.getTotalSize() + 25 > gridHeight - positionPanelHeight || isSafari
								? {
										height: gridHeight - positionPanelHeight, //tells scrollbar how big the table is
										position: 'relative',
								  }
								: {}
						}
					>
						<table
							{...{
								className: cn(styles.table),
							}}
						>
							<thead className={cn(styles.stickyHeader)}>
								{table.getHeaderGroups().map((headerGroup) => {
									return (
										<tr key={headerGroup.id}>
											<SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
												{headerGroup.headers.map((header) => (
													<DraggableTableHeader key={header.id} header={header} table={table} />
												))}
											</SortableContext>
										</tr>
									);
								})}
							</thead>

							<tbody
								style={{
									height: `${rowVirtualizer.getTotalSize() + 1.5}px`, //tells scrollbar how big the table is
									position: 'relative', //needed for absolute positioning of rows
								}}
							>
								{virtualRows.map((virtualRow) => {
									const row = rows[virtualRow.index] as Row<PositionTableItem>;

									const position = row.original.headID;

									let positionClass = position === highlightPosition;
									return (
										<tr
											key={row.id}
											data-index={virtualRow.index} //needed for dynamic row height measurement
											// ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
											className={cn(styles.tableRow, positionClass && styles.tableRowTransparent)}
											style={{
												position: 'absolute',
												transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
												width: '100%',
												height: `${virtualRow.size}px`, //this should always be a `style` as it changes on scroll
												display: 'flex',
											}}
										>
											{row.getVisibleCells().map((cell) => {
												return (
													<SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
														<DragAlongCell
															key={cell.id}
															cell={cell}
															handleSelectedInstrument={handleSelectedInstrument}
														/>
													</SortableContext>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</DndContext>
		</div>
	);
};

export default DetachWatchList;
