import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import AppContext from '../../../../../../contexts/AppContext';
import {
	isPasswordValidLength,
	isPasswordLowercaseLetter,
	isPasswordSpecialCharacters,
	isPasswordSingleUpperLetter,
	isPasswordSingleDigit,
} from '../../../../../../utils/functions/passwordValidation';

import WtrDropdown from '../../../../../components/WtrDropdown/WtrDropdown';
import { getInactivityLogoutOptions } from '../../../Settings/settingsOptions';
import { useFillContextsFromPreferences } from '../../../../../../utils/hooks/preferences/useFillContextsFromPreferences';
import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';

import MFAManagement from './MFAManagement';
import styles from './SettingsModule.module.scss';
import LangSelect from '../../../../../components/LangSelect/LangSelect';

interface paswordValidations {
	validSatisfied: boolean;
	lowercaseSatisfied: boolean;
	uppercaseSatisfied: boolean;
	digitSatisfied: boolean;
	specialSatisfied: boolean;
}
interface securitySettingsProps {
	handleUpdatePasswordSettings: (values: Record<string, string>) => void;
	setDisabled: (value: boolean) => void;
	handleCloseModal: (value: boolean) => void;
	setOpenSettings: (value: boolean) => void;
}

const SecuritySettings: React.FC<securitySettingsProps> = ({
	handleCloseModal,
	setDisabled,
	setOpenSettings,
	handleUpdatePasswordSettings,
}) => {
	const appContext = useContext(AppContext);

	const { t } = useTranslation();

	const [invalid, setInvalid] = useState(false);
	const [dontMatch, setDontMatch] = useState(false);
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [inactivityLogout, setInactivityLogout] = useState<string>(
		appContext.userPreferences!.user_prefs.platform.inactivity.toString() || ''
	);
	const [showPasswordPopup, setShowPasswordPopup] = useState(false);
	const [currentPassword, setCurrentPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [passwordValidations, setPasswordValidations] = useState<paswordValidations>({
		validSatisfied: false,
		lowercaseSatisfied: false,
		uppercaseSatisfied: false,
		digitSatisfied: false,
		specialSatisfied: false,
	});
	const fillContextsFromPreferences = useFillContextsFromPreferences();
	const { mutate: savePreferences } = useSaveUserPreferences();

	const isNewPasswordValid = (value: string) => {
		value !== '' &&
			setPasswordValidations({
				...passwordValidations,
				validSatisfied: isPasswordValidLength(value),
				lowercaseSatisfied: isPasswordLowercaseLetter(value),
				uppercaseSatisfied: isPasswordSingleUpperLetter(value),
				digitSatisfied: isPasswordSingleDigit(value),
				specialSatisfied: isPasswordSpecialCharacters(value),
			});
	};

	useEffect(() => {
		if (confirmPassword === newPassword && newPassword !== '' && confirmPassword !== '') {
			setDontMatch(false);

			if (
				!passwordValidations.validSatisfied ||
				!passwordValidations.lowercaseSatisfied ||
				!passwordValidations.uppercaseSatisfied ||
				!passwordValidations.digitSatisfied ||
				!passwordValidations.specialSatisfied
			) {
				setErrorMessage(true);
				return;
			}

			if (
				!passwordValidations.validSatisfied &&
				!passwordValidations.lowercaseSatisfied &&
				!passwordValidations.uppercaseSatisfied &&
				!passwordValidations.digitSatisfied &&
				!passwordValidations.specialSatisfied
			) {
				setInvalid(true);
				return;
			}

			if (
				passwordValidations.validSatisfied &&
				passwordValidations.lowercaseSatisfied &&
				passwordValidations.uppercaseSatisfied &&
				passwordValidations.digitSatisfied &&
				passwordValidations.specialSatisfied &&
				confirmPassword === newPassword
			) {
				setDontMatch(false);
				setInvalid(false);
				setErrorMessage(false);
				setDisabled(false);

				handleUpdatePasswordSettings({
					currentPassword: currentPassword,
					newPassword: newPassword,
					confirmPassword: confirmPassword,
				});
				return;
			}
		} else if (confirmPassword !== newPassword) {
			setDontMatch(true);
		}

		handleUpdatePasswordSettings({
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		});
	}, [newPassword, currentPassword, confirmPassword]);

	const handleInactivityLogout = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
		const intactivityValue = parseInt(`${value}`);
		if (!isNaN(intactivityValue) && intactivityValue > 0) {
			appContext.userPreferences!.user_prefs.platform.inactivity = intactivityValue;

			await savePreferences();
			fillContextsFromPreferences(appContext.userPreferences!);
		}
	};

	const findDefaultInactivity = (value: string) => {
		const defaultValue = getInactivityLogoutOptions(t).find((inactivityLogout) => inactivityLogout.value === value);
		return defaultValue?.value || '';
	};

	return (
		<>
			<div className={styles.platformTab}>
				<div className={styles.closeIcon} onClick={handleCloseModal}>
					<FontAwesomeIcon icon={['fal', 'xmark-large']} className={styles.icon} />
				</div>
				<div className={styles.profileSetingsHeader}>
					<div className={styles.platformTabName}>{t('en:SETTINGS_SECURITY')}</div>
				</div>
				<div className={styles.profileSetingsContent}>
					<div className={styles.settingsPairs}>
						<div className={styles.settingsPairsName}>{t('wtr:TWO_FACTOR_AUTHENTICATION')}</div>
						<div className={styles.settingsPairsPhone}>
							<div className={styles.checkContainer}>
								<div className={styles.toggleButton}>{<MFAManagement setOpenSettings={setOpenSettings} />}</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.profileSetingsContent}>
					<div className={styles.settingsPairs}>
						<div className={styles.settingsPairsName}>{t('wtr:INACTIVITY_LOGOUT')}</div>

						<div className={cn(styles.settingsPairsImgDropdown, styles.dropdownMenuLangWrapper)}>
							<WtrDropdown
								selection={true}
								inline={false}
								options={getInactivityLogoutOptions(t)}
								defaultValue={findDefaultInactivity(inactivityLogout.toString())}
								onChange={handleInactivityLogout}
								className={cn(styles.dropdownMenu, styles.dropdownMenuLang, styles.dropdownMenuSecurity)}
								disabled={false}
								icon={<FontAwesomeIcon className={styles.dropdownIcon} icon={['fas', 'caret-down']} />}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SecuritySettings;
