import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

import { Account, TAccountStats } from '../gateways/TfboGateway/TfboGateway.types';

import { create } from './create';

import createSelectors from './createSelectors';

export interface TradingAccountStoreActions {
	setSelected: (selected: number) => void;

	setAccounts: (accounts: any[]) => void;

	setAccountStats: (accountStats: any[]) => void;

	setCurrentWatchList: (selectedAccountStats: any) => void;
	setSelectedAccountStats: (selectedAccountStats: any) => void;
	setIsFundedTrader: (isFundedTrader: boolean) => void;
    setAccountMarketType: ({
		isJapanSpread,
		isJapanSubscription,
		isSpreadBetting,
	}: {
		isJapanSpread: boolean;
		isJapanSubscription: boolean;
		isSpreadBetting: boolean;
	}) => void;
	setCurrentAccountUrl: (url: string) => void;
	reset: () => void;
}

export interface TradingAccountStoreValues {
	selected: number;
	accounts: Account[];
	accountStats: TAccountStats[];
	selectedAccountStats: any;
	currentWatchList: string;

	// TODO: when refactoring and have time, group these status in a clever way, and update all over the application
	isSpreadBetting: boolean;
	isJapanSpread: boolean;
	isJapanSubscription: boolean;
	isFundedTrader: boolean;
	currentAccountUrl: string;
}

export const initialStateTradingAccountStore: TradingAccountStoreValues = {
	selected: -1,
	selectedAccountStats: {},
	accounts: [],
	accountStats: [],
	currentWatchList: '',
	currentAccountUrl: '',
	isSpreadBetting: false,
	isJapanSpread: false,
	isJapanSubscription: false,
	isFundedTrader: false,
};
export type TradingAccountStore = TradingAccountStoreValues & TradingAccountStoreActions;

const tradingAccountStore = create<TradingAccountStore>()(
	persist(
		immer((set: any) => ({
			...initialStateTradingAccountStore,

			setSelected: (selected: number) => set({ selected }),

			setAccountMarketType: ({
				isJapanSpread,
				isJapanSubscription,
				isSpreadBetting,
			}: {
				isJapanSpread: boolean;
				isJapanSubscription: boolean;
				isSpreadBetting: boolean;
			}) => set({ isJapanSpread, isJapanSubscription, isSpreadBetting }),

			setSelectedAccountStats: (selectedAccountStats: {}) =>
				set((state: TradingAccountStore) => {
					state.selectedAccountStats = { ...selectedAccountStats };
				}),

			setAccounts: (accounts: any) => set({ accounts }),
			setCurrentWatchList: (currentWatchList: string) =>
				set({
					currentWatchList,
				}),
			setCurrentAccountUrl: (currentAccountUrl: string) => set({ currentAccountUrl }),
			setAccountStats: (accountStats: any[]) => set({ accountStats }),
			setIsFundedTrader: (isFundedTrader: boolean) => set({ isFundedTrader }),
			reset: () => set({ ...initialStateTradingAccountStore }),
		})),
		{
			name: 'trading-account-store', // unique name
		}
	)
);

export default createSelectors(tradingAccountStore);
