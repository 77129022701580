import React, { Dispatch, SetStateAction, useContext, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '../../../../Markets/MarketsGridNew/components/TableSettingsModal.module.scss';
import { useClickOutside } from '../../../../Markets/MarketsGridNew/hooks/useClickOutside';
import AppContext from '../../../../../../contexts/AppContext';
import tradingAccountStore from '../../../../../../store/tradingAccountStore';
import DashboardContext from '../../../../../../contexts/DashboardContext';

interface SettingGridProps {
	table: any;
	setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
	name: string;
}

const SettingGrid = ({ table, setShowSettingsModal, name }: SettingGridProps) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const columns = table.getAllLeafColumns();

	const isChildWindow = appContext.isChildWindow ?? false;

	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const { t } = useTranslation();

	const settingsRef = useRef<HTMLDivElement>(null);
	const quantityType = dashboardContext.quantityType;

	// Click outside or escape key press hook
	useClickOutside(settingsRef, () => setShowSettingsModal(false));

	const changeCaption = useMemo(() => {
		const caption = t('wtr:CHANGE');
		return caption[caption.length - 1] === ':' ? caption.slice(0, -1) : caption;
	}, []);

	const translations: { [key: string]: string } = useMemo(() => {
		return {
			instrument: t('en:INSTRUMENT'),
			simulationId: t('wtr:SIMULATION'),
			side: t('en:SIDE'),
			type: t('en:Type'),
			quantityType: quantityType === 'Lots' ? t(`en:QT_LOTS`) : t(`en:QT_AMOUNT`),
			amount: t(`en:QT_AMOUNT`),
			lots: t(`en:QT_LOTS`),
			onDate: t('wtr:ON_DATE'),
			openDate: t('en:OPEN_DATE'),
			closeOn: t('wtr:WTR_CLOSED_ON'),
			open: t('en:OPEN_AT'),
			closed: t('en:CLOSE_AT'),
			change: changeCaption,
			current: t('en:CURRENT'),
			TakeProfit: t('en:TAKE_PROFIT'),
			StopLoss: t('en:STOP_LOSS'),
			netPL: t('en:NET_PL'),
			createdOn: t('wtr:CREATED_ON'),
			distanceToMarket: t('wtr:DISTANCE_TO_MARKET'),
			grossPL: t('en:GROSS_PL'),
			time: t('en:OPEN_TIME'),
			expiryOn: t('en:EXPIRY_ON'),
			swap: t('en:SWAP'),
			close: t('en:CLOSE'),
			closedTime: t('en:Close Time'),
			pipsChange: t(`wtr:WTR_PL_IN_${isSpreadBettingAccount ? 'POINTS' : 'PIPS'}`),
			comm: t('en:COMMISSION'),
			posId: t('en:POSITION_ID'),
			comment: t('en:POSITION_COMMENT'),
			dividend: t('en:DIVIDEND'),
			settings: t('en:SETTINGS'),
		};
	}, []);

	const onToggleChange = (column: any) => {
		localStorage.setItem(name, JSON.stringify(table.getState().columnVisibility));
		return column.getToggleVisibilityHandler();
	};

	return (
		<div
			ref={settingsRef}
			className={cn(
				styles.tableSettingsPopup,
				isChildWindow ? styles.tableSettingsPopupPositionChild : styles.tableSettingsPopupPosition
			)}
		>
			<div className={cn(styles.tableSettingsTitle)}>{t('wtr:COLUMN_CHOOSER')}</div>

			<div className={cn(styles.tableSettingOptionsContainer)}>
				{columns.map((column: any) => {
					const isDisabledJapan = appContext.isJapanAccount && (column.id === 'dividend' || column.id === 'comm');
					const isDisabled =
						column.id === 'instrument' ||
						column.id === 'close' ||
						column.id === 'settings' ||
						column.id === 'simulationId';

					if (isDisabled || isDisabledJapan) {
						return;
					}

					return (
						<div key={column.id} className={cn(styles.checkboxContainer)}>
							<label htmlFor={column.id} className={styles.container}>
								<input
									{...{
										id: column.id,
										className: styles.checkbox,
										type: 'checkbox',
										checked: column.getIsVisible(),
										onChange: onToggleChange(column),
									}}
								/>
								<div className={`${styles.optionText} ${isDisabled && styles.disabledCheckbox}`}>
									{translations[column.id] || column.id}
								</div>
								<span className={`${styles.checkmark} ${isDisabled && styles.disabledCheckbox}`} />
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SettingGrid;
