import React from 'react';

import SignalsList from '../../../../../pages/Signals/SignalsList/SignalsList';
import useSignals from '../../../../../utils/queries/useSignals';

import styles from './MarketsSignalsList.module.scss';
import { fakeDataForSignals } from './fakeDataForSignals';

const MarketSignalsListFake = () => {
	return (
		<div className={styles.container}>
			<SignalsList
				doNavigate
				data={fakeDataForSignals}
				hasHorizontalConfidenceLayout
				hasFilter={false}
				loading={false}
				hasError={false}
			/>
		</div>
	);
};

export default MarketSignalsListFake;
