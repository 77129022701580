import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';

import cn from 'classnames';
import { Radio } from 'semantic-ui-react';

import { Container, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Loading from '../../../components/Loading/Loading';
import { default as Nullable, Optional } from '../../../../utils/functions/Nullable';
import uniqueId from '../../../../utils/functions/uniqueId';

import { MarketItem, SelectedSymbol } from '../../../../gateways/RfpGateway/rfp.types';
import isWindowStyles from '../../../../utils/functions/isWindowStyles';
import Routes, { DETACHED_SUB_ROUTES } from '../../../../setup/routes';
import Button from '../../../../shared/Button/Button';
import Modal from '../../../../shared/Modal/Modal';
import grabIndicator from '../../../../images/grab-indicator.svg';
import { default as InstrumentContext } from '../../../../contexts/InstrumentContext';
import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import { default as StringUtils } from '../../../../utils/functions/StringUtils';
import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';
import { AccountMarketType } from '../../../../gateways/RfpGateway/rfp.types';
import { default as AppContext } from '../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../contexts/RfpGatewayContext';
import { default as ChartContext } from '../../../../contexts/ChartContext';
import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
	instrumentExistsInDynamicWatchlists,
	instrumentExistsInWatchlist,
	invalidWatchlistName,
	maxInstrumentsPerWatchlist,
	maxWatchlistsReached,
} from '../../../../utils/functions/WatchlistUtils';
import { forceCloseModal } from '../../../../utils/hooks/useForceCloseModal';
import usePreferredFeedId from '../../../../utils/hooks/usePreferredFeedId';
import {
	TWatchlistInstrument,
	TWatchlists,
} from '../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { sortSearchResults } from '../../../../utils/functions/sortSearchResults';
import { getSearchResults } from '../../../../utils/functions/getSearchResults';
import { TicketLayout } from '../../../../utils/functions/enums';

import useGetTranslatedWLName from '../../../../utils/hooks/useGetTranslatedWLName';

import WtrPopup from '../../../components/WtrPopup/WtrPopup';

import useSaveWatchlistToPreferences from '../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';

import tradingAccountStore from '../../../../store/tradingAccountStore';

import { DETACHED_WINDOW_TRADE } from '../../../../contexts/WindowContext';
import DetachIcon from '../../../components/DetachIcon/DetachIcon';
import tradingViewStore from '../../../../store/tradingViewStore';
import { useDynamicWatchLists } from '../../../../utils/hooks/useDynamicWatchLists';
import useSaveUserPreferences from '../../../../utils/mutations/useSaveUserPreferences';

import Instrument from './Instrument/Instrument';
import EmptySearch from './EmptySearch/EmptySearch';
import SearchResult from './SearchResult/SearchResult';

import WatchlistHeader from './WatchlistHeader/WatchlistHeader';

import styles from './WatchList.module.scss';
import CreateWatchlistButton from './CreateWatchlistButton/CreateWatchlistButton';
import DetachWatchList from './DetachWatchList/DetachWatchList';
import { Placement } from 'react-bootstrap/esm/types';

interface WatchlistProps {
	window1?: boolean;
	name?: string | null | undefined;
}

const Watchlist = React.memo<WatchlistProps>(
	({ window1, name }) => {
		const currentWatchList = tradingAccountStore.use.currentWatchList();
		const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
		const isFundedTrader = tradingAccountStore.use.isFundedTrader();
		const appContext = useContext(AppContext);

		const dashboardContext = useContext(DashboardContext);

		const chartContext = useContext(ChartContext);
		const instrumentContext = useContext(InstrumentContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);

		const promiseFactory = usePromiseFactory();
		const forceRerender = useForceRerender();
		const getTranslatedWLName = useGetTranslatedWLName();
		const dynamicWatchLists = useDynamicWatchLists();

		const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		const theme = appContext.appTheme;
		const isChildWindow = appContext.isChildWindow;
		const isArabic = appContext.isArabic;

		const activeTradingAccount = useSelectedTradingAccount();
		const marketItems = dashboardContext.marketItems.filter((marketItem) =>
			isSpreadBettingAccount
				? marketItem.accountMarketType === AccountMarketType.SpreadBetting
				: marketItem.accountMarketType !== AccountMarketType.SpreadBetting
		);
		const accountType = dashboardContext.accountType;
		const loadingState = dashboardContext.loadingState;
		const accountChange = dashboardContext.accountChange;
		const mappedWatchlist = dashboardContext.mappedWatchlist;
		const equityWatchlist = dashboardContext.equityWatchlist;
		const gridViewCharts = dashboardContext.gridViewCharts;
		const ticketLayout = tradingViewStore.use.ticketLayout();
		const selectedInstrument = dashboardContext.selectedInstrument;
		const selectedTradingAccountWatchlists = dashboardContext.watchlist;

		useObservable(appContext.getPropertyChangeStream('appTheme', 'userPreferences', 'email', 'isChildWindow'), () => {
			promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		});

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'marketItems',
				'accountType',
				'loadingState',
				'tradingAccount',
				'watchlistAccount',
				'accountChange',
				'notification',
				'watchlist',
				'mappedWatchlist',
				'equityWatchlist',
				'lastSymbol',
				'selectedSymbol',
				'tradingPositions',
				'newAccount',
				'chartParameters',
				'gridViewCharts',
				'toggleSort',
				'watchListStoredAsArray',
				'accountIndex',
				'selectedInstrument',
				'defaultWatchLists',
				'showOrderTicket'
			),
			() => {
				promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
					forceRerender();
				});
			}
		);

		name = name || 'default';

		const instrumentListRef = useRef<Optional<Array<{ _code: string }>>>();
		const [isWatchlistSearchOpen, setIsWatchlistSearchOpen] = useState(false);
		const [showEmptySearchState, setShowEmptySearchState] = useState(false);
		const [searchResults, setSearchResults] = useState<MarketItem[]>([]);
		const [searchValue, setSearchValue] = useState('');
		const searchInputRef = useRef<HTMLInputElement | null>(null);
		const [createWatchList, setCreateWatchList] = useState(false);
		const [typedValue, setTypedValue] = useState('');
		const [editLayer, setEditLayer] = useState(false);
		const [editWatchlistItemSelected, setEditWatchlistItemSelected] = useState(false);
		const [removeItem, setRemoveItem] = useState<any>([]);
		const [removeAll, setRemoveAll] = useState(false);
		const [openModify, setOpenModify] = useState(false);
		const [watchlistName, setWatchlistName] = useState(currentWatchList ? currentWatchList : '');
		const [openMove, setOpenMove] = useState(false);
		const [selectedWatchlist, setSelectedWatchlist] = useState('');
		const [openRemoveModal, setOpenRemoveModal] = useState(false);
		const [showPen, setShowPen] = useState(-1);
		const [dontShowWatchlist, setDontShowWatchlist] = useState<any>([]);
		const [openWatchlist, setOpenWatchlist] = useState(false);
		const [isDynamicWatchlist, setIsDynamicWatchlist] = useState(false);
		const [nameError, setNameError] = useState('');
		const [invalidMove, setInvalidMove] = useState(false);
		const [invalidRemoveName, setInvalidRemoveName] = useState('');
		const [maxWatchlistsError, setMaxWatchlistsError] = useState(false);
		const [hideCharLimit, setHideCharLimit] = useState<boolean>(false);
		const [counter, setCounter] = useState<number>(0);
		const sortOptionTracker = useRef<number>(0);
		const preferredFeedId = usePreferredFeedId(activeTradingAccount);
		const { t } = useTranslation();
		const tt = useShortTranslation('en:');
		const history = useHistory();
		const { mutate: saveUserPreference } = useSaveUserPreferences();

		const FILTER_THROTTLE = 150;

		//Reset existing errors when input length is 0
		useEffect(() => {
			typedValue.length === 0 && setNameError('');
			watchlistName.length === 0 && setNameError('');
		}, [typedValue, watchlistName]);

		//Clear existing errors when modify screen is reopened
		useEffect(() => {
			nameError.length > 0 && setNameError('');
			//eslint-disable-next-line react-hooks/exhaustive-deps
		}, [openModify]);

		useEffect(() => {
			if (dashboardContext.defaultWatchLists.length > 0) {
				if (
					instrumentExistsInDynamicWatchlists(
						currentWatchList,
						dashboardContext.defaultWatchLists,
						isSpreadBettingAccount
					)
				) {
					setIsDynamicWatchlist(true);
				} else {
					setIsDynamicWatchlist(false);
				}
			}
		}, [dashboardContext.defaultWatchLists, currentWatchList, isSpreadBettingAccount]);

		//Reset select all option if not in watchlist editing mode
		useEffect(() => {
			!editLayer && setRemoveAll(false);
		}, [editLayer]);

		useEffect(() => {
			if (sortOptionTracker.current === 0 && dashboardContext.mappedWatchlist !== null) {
				sessionStorage.setItem('manualWatchlist', JSON.stringify(dashboardContext.mappedWatchlist));
			}
			//eslint-disable-next-line react-hooks/exhaustive-deps
		}, [counter, mappedWatchlist]);

		useEffect(() => {
			if (dashboardContext.defaultWatchLists.length > 0) {
				dynamicWatchLists(dashboardContext.watchlist, isFundedTrader);
			}
		}, [dashboardContext.defaultWatchLists, isFundedTrader, dashboardContext.defaultWatchLists]);

		//Filter out invalid watchlist move options on selection of symbols during edit mode
		useEffect(() => {
			if (dashboardContext.mappedWatchlist !== null) {
				let duplicateWatchlist: any[] = [currentWatchList];
				for (const key in mappedWatchlist) {
					if (key !== currentWatchList) {
						const keyWatchlist = mappedWatchlist[key];
						const maxInstruments = maxInstrumentsPerWatchlist(mappedWatchlist, key);
						//eslint-disable-next-line
						removeItem.forEach((element: TWatchlistInstrument) => {
							if (keyWatchlist.filter((w) => w._code === element._code).length > 0 || maxInstruments) {
								duplicateWatchlist.push(key);
							}
						});
					}
					duplicateWatchlist = [...new Set(duplicateWatchlist)];
					setDontShowWatchlist(duplicateWatchlist);
				}
				duplicateWatchlist.length < Object.keys(dashboardContext.mappedWatchlist).length
					? setInvalidMove(false)
					: setInvalidMove(true);
			}
		}, [removeItem]);

		useEffect(() => {
			Nullable.of(currentWatchList)
				.filter(
					(watchlistName) =>
						(accountType === 'equity' && equityWatchlist !== null) ||
						(accountType !== 'equity' && mappedWatchlist !== null)
				)
				.run((watchlistName) => {
					const instruments: Array<{ _code: string }> = [];
					if (mappedWatchlist !== null) {
						Nullable.of(mappedWatchlist[watchlistName]).map((symbols) => {
							symbols.forEach((symbol) => {
								instruments.push(symbol);
							});
						});
					}
					instrumentListRef.current = instruments;
					subscribeToNewInstruments();
				});

			return () => {
				unsubscribeInstruments();
			};
		}, [
			equityWatchlist,
			selectedTradingAccountWatchlists,
			preferredFeedId,
			mappedWatchlist,
			currentWatchList,
			accountType,
			marketItems,
			instrumentListRef,
		]);

		useEffect(() => {
			setOpenWatchlist(false);
		}, [selectedInstrument]);

		useEffect(() => {
			if (!currentWatchList || !appContext.isJapanAccount) return;

			saveUserPreference();
		}, [currentWatchList]);
		//added rfpGatewayContext?.mapMarketItems.size because in some cases we are checking for market item before we have any
		//elements in rfpGatewayContext?.mapMarketItems this is happening for cfd accounts.
		useEffect(() => {
			if (
				rfpGatewayContext?.mapMarketItems.size !== 0 &&
				(!selectedInstrument ||
					(isSpreadBettingAccount && selectedInstrument.accountMarketType !== AccountMarketType.SpreadBetting) ||
					(!isSpreadBettingAccount && selectedInstrument.accountMarketType === AccountMarketType.SpreadBetting))
			) {
				const initialChartSymbol = appContext.isJapanAccount
					? { code: 'USDJPY' }
					: isSpreadBettingAccount
					? { code: 'GBPUSD_SB' }
					: { code: 'GBPUSD' };
				const marketItem = rfpGatewayContext?.getMarketItem(initialChartSymbol.code, DEFAULT_FEED_ID);

				if (marketItem) {
					dashboardContext.selectedInstrument = marketItem;
				}
			}
		}, [
			isSpreadBettingAccount,
			dashboardContext?.marketItems,
			rfpGatewayContext?.mapMarketItems.size,
			appContext.isJapanAccount,
		]);

		const instrumentHandlers = useMemo(() => {
			return {
				onRemovalFromWatchlist: (marketItem: any) => {
					if (accountType !== 'equity' && dashboardContext.mappedWatchlist !== null) {
						const mappedWatchlist = dashboardContext.mappedWatchlist;

						const deletionItems = Array.isArray(marketItem) ? marketItem : [marketItem];

						if (mappedWatchlist !== null) {
							let instruments = mappedWatchlist[currentWatchList];
							if (instruments !== null && Array.isArray(instruments)) {
								const startingLength = instruments.length;
								instruments = instruments.filter((instrument) => {
									return !deletionItems.some((item) => item._code === instrument._code);
								});
								if (instruments.length !== startingLength) {
									mappedWatchlist[currentWatchList] = instruments;
									return mappedWatchlist;
								}
							}
						}
					}

					dashboardContext.openInformation = -1;
				},
				onChartClick: (marketItem: MarketItem, gridViewCharts?: boolean) => {
					if (!gridViewCharts) {
						if (isChildWindow) {
							return;
						}
						if (window.innerWidth < 1024) {
							history.push(Routes.trader.charts);
						}

						dashboardContext.historicalData = [];
						dashboardContext.selectedInstrument = marketItem;
						dashboardContext.selectedType = 'Instrument';
						const newChartId = uniqueId();
						const selectedSymbols: SelectedSymbol = { [newChartId]: marketItem };
						dashboardContext.selectedSymbol = selectedSymbols;
						dashboardContext.newChart = true;
						chartContext.setSingleActiveChart(newChartId);
					} else {
						dashboardContext.symbolChanged = marketItem.code;
						dashboardContext.gridChartsChanged = true;
						dashboardContext.selectedPosition = null;
						dashboardContext.selectedInstrument = marketItem;
						dashboardContext.selectedType = 'Instrument';
					}

					//update recently viewed instruments queue
					Nullable.of(instrumentContext.instruments.find((value) => value.code === marketItem.code)).run(
						(instrument) => {
							instrumentContext.recentlyViewedInstruments.delete(instrument);
							instrumentContext.recentlyViewedInstruments.enqueue(instrument);
						}
					);
				},
			};
		}, [accountType, rfpGatewayContext, activeTradingAccount, currentWatchList]);

		const subIdRef = useRef<string | undefined>(undefined);
		const subIdItemsRef = useRef<string[] | undefined>(undefined);

		const subscribeToNewInstruments = () => {
			if (rfpGatewayContext && marketItems.length > 0) {
				if (instrumentListRef.current) {
					const symbols = instrumentListRef.current.map((item) => item._code);

					// compare symbols to avoid unnecessary subscription
					if (subIdItemsRef.current && subIdItemsRef.current.length === symbols.length) {
						const isSame = subIdItemsRef.current.every((value, index) => value === symbols[index]);
						if (isSame) {
							return;
						}
					}

					// subscribe for price quote
					const subId = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, symbols, (priceQuote) => {});
					//console.log(`✅︎ Subscribe instruments subId=${subId} symbols=${symbols}`);
					subIdRef.current = subId;
					subIdItemsRef.current = symbols;
				}
			}
		};

		const unsubscribeInstruments = () => {
			// unsubscribe price quote on unmount
			return () => {
				if (rfpGatewayContext && subIdRef.current) {
					//console.log(`❌ Unsubscribe instruments subId=${subIdRef.current}`);
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
					subIdRef.current = undefined;
					subIdItemsRef.current = undefined;
				}
			};
		};

		const setCurrentItemWatchList = (item: any) => {
			if (mappedWatchlist !== null) {
				setCurrentWatchList(item);
				setWatchlistName(item);
				setOpenWatchlist(false);
			}
		};

		const changeValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			setTypedValue(value);
			nameError.length > 0 && setNameError('');
		};

		const changeWatchlistName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			setWatchlistName(value);
			if (nameError || invalidRemoveName) {
				setNameError('');
				setInvalidRemoveName('');
			}
		};

		const handleCreateWatchlist = () => {
			if (!maxWatchlistsError) {
				setTypedValue('');
				setCreateWatchList(true);
			}
		};

		const handleCreateWatchListClose = () => {
			setNameError('');
			setTypedValue('');
			setCreateWatchList(false);
		};

		const setEditWwatchlist = () => {
			setCounter(0);
			sortOptionTracker.current = 0;
			setOpenWatchlist(false);
			setEditLayer(true);
		};

		const resetEditLayer = () => {
			setEditLayer(false);
			if (mappedWatchlist !== null) {
				Nullable.of(mappedWatchlist[currentWatchList])
					.filter(Array.isArray)
					.run((watchlist) => {
						watchlist.forEach((value, index) => {
							Nullable.of(document.getElementById(`instrumentChecked_${index}`)).run((element) => {
								element.removeAttribute('checked');
							});
						});
					});
			}
			setEditWatchlistItemSelected(false);
			setRemoveItem([]);
			setDontShowWatchlist([]);
		};

		const verifyWatchlist = () => {
			if (mappedWatchlist !== null) {
				const nameExists = invalidWatchlistName(typedValue, mappedWatchlist);
				const maxWatchlists = maxWatchlistsReached(mappedWatchlist);
				if (!nameExists && !maxWatchlists) {
					setNameError('');
					handleSaveWatchlist();
				} else if (nameExists) {
					setNameError(t('wtr:NAME_IN_USE'));
				}
			}
		};
		const handleSaveWatchlist = () => {
			const watchlistName = typedValue.trim();
			const newWatchlistEntry: TWatchlists = {
				instrument: [],
				_feedId: preferredFeedId !== '' ? preferredFeedId : DEFAULT_FEED_ID,
				_id: watchlistName,
				_name: watchlistName,
				_sortOrder: 'None',
				_sortValue: 'Instrument',
			};

			selectedTradingAccountWatchlists.push(newWatchlistEntry);
			dashboardContext.watchlist = selectedTradingAccountWatchlists;
			const updateWatchlistMap = createWatchlistMap(dashboardContext.watchlist);
			dashboardContext.mappedWatchlist = updateWatchlistMap;
			setCurrentWatchList(watchlistName);
			saveWatchlistToPreferences(selectedTradingAccountWatchlists);
			setCreateWatchList(false);
			setOpenWatchlist(false);
		};

		const handleWatchListSelection = (event: any, instrument: any) => {
			Nullable.of(mappedWatchlist)
				.map((mappedWatchlist) => mappedWatchlist[currentWatchList])
				.map((instruments) => {
					return {
						instruments: instruments,
						targetInstrumentCode: instrument != null ? instrument._code : null,
					};
				})
				.filter((args) => Array.isArray(args.instruments) && args.targetInstrumentCode != null)
				.run((args) => {
					const instruments = args.instruments;
					const targetInstrumentCode = args.targetInstrumentCode;
					instruments.forEach((instrumentItem, index) => {
						Nullable.of(document.getElementById(`instrumentChecked_${index}`)).run((element) => {
							if (instrumentItem._code === targetInstrumentCode) {
								if (editWatchlistItemSelected) {
									element.removeAttribute('checked');
								} else {
									element.setAttribute('checked', true as any);
								}
							}
							if ((element as any).checked) {
								if (Array.isArray(removeItem) && !removeItem.some((item) => item._code === targetInstrumentCode)) {
									setRemoveItem([...removeItem, instrument]);
								}
							} else if (
								!(element as any).checked &&
								(event == null || event.target == null || !event.target.checked)
							) {
								let deleteIndex: any = [];
								let copyItem = [...removeItem];
								removeItem.forEach((item: any, idx: number) => {
									if (item === instrument) {
										deleteIndex.push(idx);
									}
								});
								const distinctDeleteIndex = deleteIndex.filter((d: any, i: any) => {
									return deleteIndex.indexOf(d) === i;
								});
								const updatedRemoveItem = copyItem.filter(function (value, index) {
									return distinctDeleteIndex.indexOf(index) === -1;
								});
								setRemoveAll(false);
								setEditWatchlistItemSelected(false);
								if (updatedRemoveItem.length === 0) {
									setRemoveAll(false);
									setEditWatchlistItemSelected(false);
								}
								setRemoveItem(updatedRemoveItem);
							}
						});
					});
				});
		};

		const handleAllWatchListSelection = (event: any) => {
			let selectItem: any = [];
			if (editWatchlistItemSelected && mappedWatchlist !== null) {
				setEditWatchlistItemSelected(false);
				mappedWatchlist[currentWatchList].forEach((cItem, index: number) => {
					let instrumentChecked: any = document.getElementById(`instrumentChecked_${index}`);
					instrumentChecked.checked = false;
				});
				setRemoveAll(false);
			} else if (mappedWatchlist !== null) {
				setEditWatchlistItemSelected(true);
				mappedWatchlist[currentWatchList].forEach((cItem, index: number) => {
					let instrumentChecked: any = document.getElementById(`instrumentChecked_${index}`);
					instrumentChecked.checked = true;
					selectItem.push(cItem);
					setRemoveItem(selectItem);
				});
				setRemoveAll(true);
			}
			if (!event.target.checked) {
				setRemoveItem([]);
			}
		};

		const handleRemoveItem = () => {
			setOpenRemoveModal(false);
			if (mappedWatchlist !== null) {
				if (removeAll) {
					let copyWatchlist = mappedWatchlist;
					copyWatchlist[currentWatchList] = [];
					const originalList = selectedTradingAccountWatchlists.find(
						(watchlist) => watchlist._name === currentWatchList
					);
					if (originalList) {
						originalList.instrument = [];
						dashboardContext.watchlist = selectedTradingAccountWatchlists;
					}
					setCurrentWatchList(currentWatchList);
					setEditLayer(false);
				} else {
					let selectedWatchlist = selectedTradingAccountWatchlists.find(
						(watchlist) => watchlist._name === currentWatchList
					);
					removeItem.forEach((item: any) => {
						if (selectedWatchlist) {
							selectedWatchlist.instrument = selectedWatchlist.instrument.filter(
								(instrument) => instrument._code !== item._code
							);
						}
					});
					const result = instrumentHandlers.onRemovalFromWatchlist(removeItem);
					dashboardContext.mappedWatchlist = result ? result : null;
					mappedWatchlist[currentWatchList].forEach((cItem, index: number) => {
						let instrumentChecked: any = document.getElementById(`instrumentChecked_${index}`);
						instrumentChecked.checked = false;
					});
				}
				saveWatchlistToPreferences(selectedTradingAccountWatchlists);
			}
			setRemoveItem([]);
			setRemoveAll(false);
			setEditLayer(false);
			forceCloseModal('fade modal');
		};

		const handleCancelRemove = () => {
			setOpenRemoveModal(false);
			forceCloseModal('fade modal');
		};

		useEffect(() => {
			watchlistNameRef.current = currentWatchList;

			setCounter(0);
		}, [currentWatchList]);

		const watchlistNameRef = useRef<string>(currentWatchList);

		const saveNewWatchlist = () => {
			if (mappedWatchlist !== null) {
				const name = watchlistName.trim();
				const validateName = invalidWatchlistName(name, mappedWatchlist);
				if (!validateName) {
					if (watchlistNameRef.current === name && Object.keys(mappedWatchlist).length === 1) {
						setOpenModify(false);
						dashboardContext.watchlist = selectedTradingAccountWatchlists;
						setCurrentWatchList(name);
						watchlistNameRef.current = name;
					} else {
						setOpenModify(false);
						const originalName = selectedTradingAccountWatchlists.find(
							(watchlist) => watchlist._name === currentWatchList
						);
						if (originalName) {
							originalName._name = name;
							originalName._id = name;
						}
						let cloneWatchlist = mappedWatchlist;
						cloneWatchlist[name] = cloneWatchlist[currentWatchList];
						delete cloneWatchlist[currentWatchList];
						setCurrentWatchList(name);
						watchlistNameRef.current = name;
						saveWatchlistToPreferences(selectedTradingAccountWatchlists);
					}
					forceCloseModal('fade modal');
				} else {
					setNameError(t('wtr:NAME_IN_USE'));
					setOpenModify(true);
				}
			}
		};

		const removeWatchlist = () => {
			if (dashboardContext.mappedWatchlist !== null) {
				if (!invalidWatchlistName(watchlistName.trim(), dashboardContext.mappedWatchlist!)) {
					setInvalidRemoveName('Watchlist does not exist');
				} else if (dashboardContext.mappedWatchlist !== null) {
					let copyWatchlist = dashboardContext.mappedWatchlist!;
					delete copyWatchlist[watchlistName];
					const removed = selectedTradingAccountWatchlists.filter(
						(watchlist) => watchlist._name.toUpperCase() !== watchlistName.toUpperCase()
					);
					dashboardContext.watchlist = removed;
					dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
					const watchlistNames = Object.keys(copyWatchlist);

					setCurrentWatchList(watchlistNames[0]);
					saveWatchlistToPreferences(dashboardContext.watchlist);
					setOpenModify(false);
					forceCloseModal('fade modal');
				}
			}
		};

		const handleWatchlistSelectionOne = (item: any) => {
			setSelectedWatchlist(item);
		};

		const handleMoveClose = () => {
			setOpenMove(false);
			setSelectedWatchlist('');
			forceCloseModal('fade modal');
		};

		const moveTowatchlist = () => {
			if (mappedWatchlist !== null) {
				let moveWatchlist = mappedWatchlist;
				const getWatchlist = moveWatchlist[currentWatchList];
				const copyGetWatchlist = [...getWatchlist];
				let moveIndex = -1;
				let moveMultipleIndex: any = [];
				let updatedMoveWatchlist: any = [];
				if (removeItem.length === 1) {
					moveIndex = getWatchlist.indexOf(removeItem[0]);
					copyGetWatchlist.splice(moveIndex, 1);
				} else {
					removeItem.forEach((element: any) => {
						moveMultipleIndex.push(getWatchlist.indexOf(element));
					});
					const distinctDeleteIndex = moveMultipleIndex.filter((d: any, i: any) => {
						return moveMultipleIndex.indexOf(d) === i;
					});
					let newWatchlist = [...getWatchlist];
					updatedMoveWatchlist = newWatchlist.filter(function (value, index) {
						return distinctDeleteIndex.indexOf(index) === -1;
					});
				}
				moveWatchlist[currentWatchList] = removeItem.length === 1 ? copyGetWatchlist : updatedMoveWatchlist;
				const moveToWatchlist: any = moveWatchlist[selectedWatchlist];
				const copyMoveToWatchlist = [...moveToWatchlist];
				if (removeItem.length === 1) {
					if (copyMoveToWatchlist.length === 0) {
						copyMoveToWatchlist.push(removeItem[0]);
					} else {
						if (removeItem[0]) {
							copyMoveToWatchlist.push(removeItem[0]);
						} else {
							copyMoveToWatchlist.push(removeItem);
						}
					}
					moveWatchlist[selectedWatchlist] =
						copyMoveToWatchlist.length === 0 ? copyMoveToWatchlist[0] : copyMoveToWatchlist;
				} else {
					removeItem.forEach((item: any) => {
						copyMoveToWatchlist.push(item);
					});
					let distinctMoveWatchlist: any = [...new Set(copyMoveToWatchlist)];
					moveWatchlist[selectedWatchlist] = distinctMoveWatchlist;
				}
				const updateStartingWatchlist = selectedTradingAccountWatchlists.find(
					(watchlist) => watchlist._name === selectedWatchlist
				);
				const updateEndingWatchlist = selectedTradingAccountWatchlists.find(
					(watchlist) => watchlist._name === currentWatchList
				);
				if (updateStartingWatchlist) {
					updateStartingWatchlist.instrument = mappedWatchlist[selectedWatchlist];
				}
				if (updateEndingWatchlist) {
					updateEndingWatchlist.instrument = mappedWatchlist[currentWatchList];
				}
				setOpenMove(false);
				mappedWatchlist[currentWatchList].forEach((cItem: any, index: number) => {
					let instrumentChecked: any = document.getElementById(`instrumentChecked_${index}`);
					instrumentChecked.checked = false;
				});
				saveWatchlistToPreferences(selectedTradingAccountWatchlists);
			}
			setEditWatchlistItemSelected(false);
			setRemoveItem([]);
			setRemoveAll(false);
			setEditLayer(false);
			forceCloseModal('fade modal');
		};

		useEffect(() => {
			if (mappedWatchlist !== null) {
				if (maxWatchlistsReached(mappedWatchlist)) {
					setMaxWatchlistsError(true);
				} else {
					setMaxWatchlistsError(false);
				}
			}
		}, [handleCreateWatchlist]);

		const reorder = (list: any, startIndex: any, endIndex: any) => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		};

		const onDragEnd = (result: any) => {
			if (!result.destination) {
				return;
			}
			if (dashboardContext.mappedWatchlist !== null) {
				const items = reorder(
					dashboardContext.mappedWatchlist![currentWatchList],
					result.source.index,
					result.destination.index
				);
				const updatePreferenceObject = selectedTradingAccountWatchlists.find(
					(watchlist) => watchlist._name === currentWatchList
				);
				if (updatePreferenceObject) {
					updatePreferenceObject.instrument = items as TWatchlistInstrument[];
					const watchlistObject = {
						...dashboardContext.mappedWatchlist!,
						[currentWatchList]: [...(items as TWatchlistInstrument[])],
					};
					dashboardContext.mappedWatchlist = watchlistObject;
					saveWatchlistToPreferences(selectedTradingAccountWatchlists);
					sessionStorage.setItem('manualWatchlist', JSON.stringify(dashboardContext.mappedWatchlist));
				}
			}
		};

		const handleCounter = (e: any) => {
			Nullable.of(e)
				.map((e) => e.target)
				.map((target: HTMLElement) => target.classList)
				.filter(
					(classList) =>
						classList.length > 0 && (classList[0].includes('instrument') || classList[0].includes('windowInstrument'))
				)
				.run((classList) => {
					sortOptionTracker.current = sortOptionTracker.current + 1 > 2 ? 0 : sortOptionTracker.current + 1;
					sortWatchlist(sortOptionTracker.current);
				});
		};

		const sortWatchlist = (counter: number) => {
			//Restore version of watchlist before sorting occured / original copy
			if (counter === 0 && sessionStorage.getItem('manualWatchlist') !== null) {
				const manualWatchlist = sessionStorage.getItem('manualWatchlist');
				dashboardContext.mappedWatchlist = manualWatchlist !== null && JSON.parse(manualWatchlist);
			}

			//Ascending Sort
			else if (counter === 1 && dashboardContext.mappedWatchlist !== null) {
				const copy = dashboardContext.mappedWatchlist![currentWatchList];
				copy.sort((x1, x2) => x1._code.localeCompare(x2._code) * 1);
				dashboardContext.mappedWatchlist = { ...dashboardContext.mappedWatchlist, [currentWatchList]: copy };

				//Descending Sort
			} else if (counter === 2 && dashboardContext.mappedWatchlist !== null) {
				const copy = dashboardContext.mappedWatchlist![currentWatchList];
				copy.sort((x1, x2) => x1._code.localeCompare(x2._code) * -1);
				dashboardContext.mappedWatchlist = { ...dashboardContext.mappedWatchlist, [currentWatchList]: copy };
			}
		};

		const inputStyleSelector = () => {
			return !StringUtils.isNullOrEmpty(nameError)
				? styles.lightErrorInput
				: ticketLayout === TicketLayout.Dock
				? styles.costInput
				: styles.costInputModal;
		};

		const handleCloseModifyModal = () => {
			setOpenModify(false);
			setNameError('');
			setInvalidRemoveName('');
			setTypedValue('');
			forceCloseModal('fade modal');
		};

		const handleModify = (item: string) => {
			setCurrentItemWatchList(item);
			setOpenModify(true);
		};

		const closeWatchlistPopup = (e: any) => {
			if (
				e.target.classList[0] !== undefined ||
				e.target.classList.length === 0 ||
				(Array.isArray(e.target.classList) && !e.target.classList[0].includes('Watchlist'))
			) {
				setOpenWatchlist(false);
			}
		};

		const handleSearchValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			if (value.length <= 1) {
				setSearchResults([]);
				setShowEmptySearchState(false);
			} else {
				promiseFactory.throttle('searchTermChange', FILTER_THROTTLE).then(() => {
					const results = getSearchResults(marketItems, value);
					if (results.length === 0) {
						setSearchResults([]);
						setShowEmptySearchState(true);
					} else {
						const sorted = sortSearchResults(results, value);
						setSearchResults(sorted);
						setShowEmptySearchState(false);
					}
				});
			}
			setSearchValue(value.trim());
		};

		const clearSearchInput = () => {
			setSearchValue('');
			setSearchResults([]);
			setShowEmptySearchState(false);
			searchInputRef.current !== null && searchInputRef.current.focus();
		};

		const resetWatchlistSearch = () => {
			setIsWatchlistSearchOpen(false);
			setSearchResults([]);
			setSearchValue('');
			setShowEmptySearchState(false);
		};

		const updateWatchlist = (action: 'add' | 'remove', symbol: string) => {
			let preferenceToUpdate = selectedTradingAccountWatchlists.find(
				(preference) => preference._name === currentWatchList
			);
			if (preferenceToUpdate) {
				if (action === 'add') {
					preferenceToUpdate.instrument = [...preferenceToUpdate.instrument, { _code: symbol }];
				} else {
					preferenceToUpdate.instrument = preferenceToUpdate.instrument.filter(
						(instrument) => instrument._code !== symbol
					);
				}
				dashboardContext.watchlist = selectedTradingAccountWatchlists;
				dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
				saveWatchlistToPreferences(dashboardContext.watchlist);
			}
		};

		const editOptionContainer = (
			<div id="action-row" className={styles.actionRow}>
				<div className={styles.editOptionContainer}>
					<label
						className={cn(
							styles.selectAll,
							dashboardContext.mappedWatchlist !== null &&
								dashboardContext.mappedWatchlist[currentWatchList]?.length === 0
								? styles.disableBtn
								: '',
							styles.editOptionCheckboxContainer
						)}
					>
						<input
							onChange={(event) => handleAllWatchListSelection(event)}
							type="checkbox"
							id={`instrumentAllChecked`}
							checked={removeAll}
						></input>
						<span className={styles.checkmark}></span>
						<span className={styles.textAll}>{t('wtr:SELECT_ALL')}</span>
					</label>
				</div>
				<div className={styles.editOptionContainer}>
					<label
						className={cn(
							styles.move,
							removeItem.length === 0 ||
								invalidMove ||
								(mappedWatchlist !== null && Object.keys(mappedWatchlist).length === 1)
								? styles.disableBtn
								: ''
						)}
						onClick={() => setOpenMove(true)}
					>
						<FontAwesomeIcon icon={['fas', 'truck']} className={styles.editOptionIcons} />
						<span className={styles.editBarText}>{tt('WATCHLIST_MOVE_BTN_TITLE')}</span>
					</label>
				</div>
			</div>
		);

		return (
			<>
				{!editLayer && (
					<div className={styles.container}>
						<div
							className={isWindowStyles(
								cn(styles.header, isDynamicWatchlist && styles.headerDynamic),
								styles.windowHeader,
								window1
							)}
						>
							{!isDynamicWatchlist && (
								<OverlayTrigger
									delay={{ show: 750, hide: 0 }}
									key={'editWatchlist'}
									placement={isArabic ? 'left' : 'right'}
									overlay={
										<Tooltip className="my-tooltip" id={'editWatchlist'}>
											{t('en:EDIT_WATCHLIST')}
										</Tooltip>
									}
								>
									<div
										className={cn(
											isWindowStyles(styles.editIcon, styles.windowHeader, window1),
											dashboardContext.mappedWatchlist !== null &&
												dashboardContext.mappedWatchlist[currentWatchList] &&
												dashboardContext.mappedWatchlist[currentWatchList].length === 0
												? styles.disableBtn
												: ''
										)}
										onClick={() => setEditWwatchlist()}
									>
										<FontAwesomeIcon
											icon={['far', 'pen']}
											size="1x"
											className={cn(styles.icon, styles[`${theme}PenIcon`])}
										/>
									</div>
								</OverlayTrigger>
							)}
							{openModify && (
								<div className={cn(styles.openModalTitle, styles[`${theme}Title`])}>
									{getTranslatedWLName(currentWatchList)} &nbsp;
									<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
								</div>
							)}
							<div>
								{!openModify && (
									<WtrPopup
										className={styles.Popup}
										open={openWatchlist}
										onClose={closeWatchlistPopup}
										content={
											!createWatchList ? (
												<>
													<div className={styles.watchlists}>
														{dashboardContext.mappedWatchlist !== null &&
															Object.keys(dashboardContext.mappedWatchlist).map((item, index: number) => {
																const tierName = getTranslatedWLName(item);

																return (
																	<div
																		key={index}
																		className={styles.listContainer}
																		onMouseOver={() => setShowPen(index)}
																	>
																		<div
																			className={styles.itemContainer}
																			onClick={() => {
																				setCurrentItemWatchList(item);
																			}}
																		>
																			<div
																				className={cn(
																					styles.watchlistItem,
																					instrumentExistsInDynamicWatchlists(
																						item,
																						dashboardContext.defaultWatchLists,
																						isSpreadBettingAccount
																					) && styles.watchlistDynamicItem
																				)}
																			>
																				<FontAwesomeIcon
																					className={cn(styles.icon, item === currentWatchList && styles.active)}
																					icon={['fas', 'list']}
																				/>
																				<span
																					className={styles.watchlistName}
																					id={`${item}_${index}`}
																					onMouseOver={() => setShowPen(index)}
																				>
																					{tierName}
																				</span>
																			</div>
																			{!instrumentExistsInDynamicWatchlists(
																				item,
																				dashboardContext.defaultWatchLists,
																				isSpreadBettingAccount
																			) && (
																				<FontAwesomeIcon
																					icon={['fas', 'pen']}
																					size="1x"
																					className={styles.icon}
																					onClick={() => handleModify(item)}
																				/>
																			)}
																		</div>
																	</div>
																);
															})}
													</div>
													<CreateWatchlistButton
														maxWatchlistsError={maxWatchlistsError}
														handleCreateWatchlist={handleCreateWatchlist}
													/>
												</>
											) : (
												<>
													<div className={styles.createWLContainer}>
														<div className={styles.createWatchlistItems}>
															<span className={styles.createWatchlistTitle}> {tt('CREATE_WATCHLIST')}</span>
														</div>
														<div className={styles.inputContainer}>
															<div className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</div>
															<div className={styles.inputBox}>
																<input
																	type="text"
																	className={inputStyleSelector()}
																	maxLength={20}
																	value={typedValue}
																	onChange={changeValue}
																	autoFocus
																	disabled={hideCharLimit}
																/>
															</div>
															<div className={styles.messageRow}>
																{nameError.length !== 0 && <div className={styles.errorMessage}>{nameError}</div>}
																{!hideCharLimit && (
																	<div className={styles.inputContainerText}> {typedValue.trim().length}/20</div>
																)}
															</div>
														</div>
														<div className={styles.popupFooter}>
															<Button
																variant="secondary"
																size="sm"
																label={tt('CANCEL')}
																onClick={handleCreateWatchListClose}
															/>
															<Button
																variant="primary"
																size="sm"
																label={tt('CREATE')}
																disabled={nameError.length > 0 || typedValue.trim().length === 0}
																onClick={verifyWatchlist}
															/>
														</div>
													</div>
												</>
											)
										}
										on={['click']}
										pinned
										position="bottom center"
										trigger={
											currentWatchList && (
												<div
													className={cn(styles.Title, styles[`${theme}Title`])}
													onClick={() => setOpenWatchlist(true)}
												>
													{getTranslatedWLName(currentWatchList)} &nbsp;
													<FontAwesomeIcon icon={['fas', 'caret-down']} size="1x" />
												</div>
											)
										}
									/>
								)}
							</div>
							<div className={styles.headerButtonsWrapper}>
								{!isDynamicWatchlist && (
									<WtrPopup
										className={styles.watchlistSearchContainer}
										open={isWatchlistSearchOpen}
										onClose={resetWatchlistSearch}
										on="click"
										pinned
										position={`${isChildWindow ? 'bottom right' : 'bottom center'}`}
										trigger={
											<OverlayTrigger
												delay={{ show: 750, hide: 0 }}
												key="addIcon"
												placement={`${isChildWindow ? 'bottom' : 'top'}` as Placement}
												overlay={
													<Tooltip className="my-tooltip" id="icon">
														{tt('ADD_INSTRUMENT')}
													</Tooltip>
												}
											>
												<span className={styles.addInstrumentContainer}>
													<FontAwesomeIcon
														icon={['far', 'plus-circle']}
														className={styles.addToWatchlistIcon}
														size="2x"
														onClick={() => setIsWatchlistSearchOpen(!isWatchlistSearchOpen)}
													></FontAwesomeIcon>
												</span>
											</OverlayTrigger>
										}
										content={
											<>
												<div className={styles.searchContainer}>
													<div className={styles.searchTitle}>
														{t('wtr:ADD_INSTRUMENT_TO')} {getTranslatedWLName(currentWatchList)}
													</div>
													<div className={styles.searchInputContainer}>
														<FontAwesomeIcon icon={['fas', 'search']} className={styles.inputIcon}></FontAwesomeIcon>
														<FontAwesomeIcon
															icon={['fas', 'times']}
															onClick={clearSearchInput}
															className={searchValue.length > 0 ? styles.deleteIcon : styles.hidden}
														></FontAwesomeIcon>
														<input
															className={cn(
																searchResults.length > 0 || showEmptySearchState
																	? styles.openSearchInput
																	: styles.searchInput
															)}
															type="text"
															placeholder={tt('ADD_INSTRUMENT')}
															autoFocus
															ref={searchInputRef}
															value={searchValue}
															onChange={handleSearchValue}
															name="search"
															spellCheck="false"
															autoComplete="off"
														/>
													</div>
												</div>
												<div
													className={cn(
														styles.searchResults,
														showEmptySearchState && styles.hiddenScroll,
														searchResults.length > 0 && styles.boxSearch
													)}
												>
													{searchResults.length > 0 &&
														dashboardContext.mappedWatchlist !== null &&
														searchResults.map((result) => {
															const existsInWatchlist = instrumentExistsInWatchlist(
																dashboardContext.mappedWatchlist!,
																currentWatchList,
																result
															);
															let watchlists: string[] = [];
															if (existsInWatchlist) {
																watchlists = instrumentContext.watchlistsforInstrument(
																	dashboardContext.mappedWatchlist!,
																	result
																);
															}
															return (
																<SearchResult
																	key={result.code}
																	searchTerm={searchValue}
																	result={result}
																	existsInWatchlist={existsInWatchlist}
																	onUpdateWatchlist={() =>
																		updateWatchlist(existsInWatchlist ? 'remove' : 'add', result.code)
																	}
																	watchlists={watchlists}
																/>
															);
														})}
													{showEmptySearchState && <EmptySearch invalidSearchTerm={searchValue} />}
												</div>
											</>
										}
									/>
								)}
								<DetachIcon url={DETACHED_SUB_ROUTES.Trade} windowType={DETACHED_WINDOW_TRADE} />
							</div>
							{/* TODO: DELETE ME */}
							{/* {!isChildWindow && <PanelActions popoutClickHandler={popoutClickHandler} />} */}
						</div>
						{!isChildWindow ? (
							currentWatchList &&
							dashboardContext.watchlist && (
								<Container fluid className={styles.containerFluid}>
									{dashboardContext.mappedWatchlist !== null && dashboardContext.mappedWatchlist[currentWatchList] ? (
										dashboardContext.mappedWatchlist !== null &&
										dashboardContext.mappedWatchlist[currentWatchList].length > 0 && (
											<>
												<div className={styles.instrumentBox} onClick={handleCounter}>
													<WatchlistHeader
														clickCount={counter}
														window={window1}
														isEquity={accountType === 'equity'}
														editLayer={editLayer}
													/>
												</div>
												<div
													className={
														dashboardContext.mappedWatchlist[currentWatchList].length < 13
															? cn(styles.MarketTickerContainer, styles.noScrollBar)
															: cn(!window1 ? styles.MarketTickerContainer : styles.windowMarketTickerContainer)
													}
												>
													{accountChange && (
														<div className={styles.loadingWatchlist}>
															<Loading />
														</div>
													)}
													{!accountChange && (
														<div className={styles.instrumentsContainer}>
															{dashboardContext.mappedWatchlist[currentWatchList] &&
																dashboardContext.mappedWatchlist[currentWatchList].map((instrument, i) => {
																	const mktItem = rfpGatewayContext?.getMarketItem(instrument._code, DEFAULT_FEED_ID);
																	return (
																		mktItem && (
																			<Instrument
																				showInstrumentName={true}
																				showPriceStats={true}
																				resizeButton={false}
																				spreadPosition={false}
																				index={i}
																				key={i}
																				marketItem={mktItem}
																				windows={window1}
																				parent="watchlist"
																				onRemovalFromWatchlist={() =>
																					instrumentHandlers.onRemovalFromWatchlist(mktItem)
																				}
																				onChartClick={() => instrumentHandlers.onChartClick(mktItem, gridViewCharts)}
																				showJapanTier={appContext.isJapanAccount}
																			/>
																		)
																	);
																})}
														</div>
													)}
													<div className={styles.notSticky}>
														{!isDynamicWatchlist && (
															<div
																className={
																	dashboardContext.mappedWatchlist !== null &&
																	Object.keys(dashboardContext.mappedWatchlist).length === 0
																		? styles.centeredText
																		: styles.addInstrument
																}
																onClick={() => setIsWatchlistSearchOpen(true)}
															>
																{!window1 && (
																	<>
																		<FontAwesomeIcon
																			icon={['far', 'plus-circle']}
																			className={styles.addInstrumentIcon}
																		/>
																		<span className={cn(styles.Title, styles[`${theme}Title`], styles.instrumentTitle)}>
																			{tt('ADD_INSTRUMENT')}
																		</span>
																	</>
																)}
															</div>
														)}
													</div>
												</div>
											</>
										)
									) : (
										<div></div>
									)}
									{dashboardContext.mappedWatchlist !== null &&
									typeof dashboardContext.mappedWatchlist[currentWatchList] !== 'undefined' ? (
										currentWatchList &&
										dashboardContext.mappedWatchlist[currentWatchList].length === 0 && (
											<div className={cn(isWindowStyles(styles.emptyContainer, styles.emptyWindowContainer, window1))}>
												<div className={styles.emptyStar}>
													<FontAwesomeIcon
														icon={['fas', 'tasks']}
														className={cn(styles.iconC)}
														aria-hidden="true"
													></FontAwesomeIcon>
												</div>
												<div className={styles.emptyTitle}>{t('wtr:WTR_EMPTY_WATCHLIST')}</div>
												<div className={styles.notSticky}>
													<div className={styles.centeredText} onClick={() => setIsWatchlistSearchOpen(true)}>
														<FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addInstrumentIcon} />
														<span className={cn(styles.Title, styles[`${theme}Title`], styles.instrumentTitle)}>
															{tt('ADD_INSTRUMENT')}
														</span>
													</div>
												</div>
											</div>
										)
									) : (
										<></>
									)}
								</Container>
							)
						) : (
							<DetachWatchList />
						)}
					</div>
				)}
				{editLayer && (
					<div className={styles.container}>
						<div className={isWindowStyles(styles.editHeader, styles.windowEditHeader, window1)}>
							<div className={isWindowStyles(styles.editIcon, styles.windowHeader, window1)} onClick={resetEditLayer}>
								<FontAwesomeIcon icon={['fas', 'chevron-left']} size="1x" className={styles.icon} />
							</div>
							<div className={cn(styles.openModalTitle, styles[`${theme}Title`])}>
								{' '}
								{getTranslatedWLName(currentWatchList)}{' '}
							</div>
							<div className={styles.removeButton}>
								<label
									className={cn(styles.remove, removeItem.length === 0 ? styles.disableBtn : '')}
									onClick={() => setOpenRemoveModal(!openRemoveModal)}
								>
									<FontAwesomeIcon icon={['fas', 'trash-alt']} className={styles.removeItemsIcon} />
								</label>
							</div>
						</div>

						{currentWatchList &&
							dashboardContext.mappedWatchlist !== null &&
							Object.values(dashboardContext.mappedWatchlist).length > 0 && (
								<div className={isWindowStyles(styles.WatchListCategories, styles.windowWatchListCategories, window1)}>
									{dashboardContext.mappedWatchlist![currentWatchList].length > 0 && (
										<div
											id="edit-scroll"
											className={isWindowStyles(
												styles.WatchListCategoriesChildEdit,
												styles.windowEditDragContainer,
												window1
											)}
										>
											<div className={styles.instrumentBox}>
												<WatchlistHeader
													clickCount={setCounter}
													window={window1}
													isEquity={accountType === 'equity'}
													editLayer={editLayer}
												/>
											</div>
											<DragDropContext onDragEnd={onDragEnd}>
												<Droppable droppableId="droppable">
													{(provided) => (
														<div
															id="edit-droppable"
															className={cn(
																!window ? styles.MarketTickerContainer : styles.windowMarketTickerContainerEdit
															)}
															{...provided.droppableProps}
															ref={provided.innerRef}
														>
															{dashboardContext.mappedWatchlist![currentWatchList].map((instrument, i) => {
																const mktItem = rfpGatewayContext?.getMarketItem(instrument._code, DEFAULT_FEED_ID);
																return (
																	mktItem && (
																		<Draggable key={mktItem.code} draggableId={mktItem.code} index={i}>
																			{(provided) => (
																				<div
																					className={cn(
																						isWindowStyles(styles.editContainer, styles.windowEditContainer, window1)
																					)}
																					data-testid="instrumentList"
																					ref={provided.innerRef}
																					{...provided.draggableProps}
																					{...provided.dragHandleProps}
																				>
																					<label
																						className={cn(
																							styles.checkboxContainer,
																							isWindowStyles(styles.TickerInfo, styles.windowTickerInfo, window1)
																						)}
																					>
																						<input
																							onChange={(event) => handleWatchListSelection(event, instrument)}
																							type="checkbox"
																							id={`instrumentChecked_${i}`}
																						></input>
																						<span className={styles.checkmark}></span>
																						<div className={styles.tickerContainer} data-testid="chart">
																							<div className={cn(styles.Symbol, styles[`${theme}Symbol`])}>
																								{mktItem.exchangeTicker && mktItem.exchangeTicker !== ''
																									? mktItem.exchangeTicker
																									: mktItem.code}
																							</div>
																							{accountType !== 'equity' && (
																								<div
																									className={cn(
																										styles.SymbolDescription,
																										styles[`${theme}SymbolDescription`]
																									)}
																								>
																									{mktItem.fullName}
																								</div>
																							)}
																						</div>
																					</label>
																					<div className={styles.grabIndicatorContainer}>
																						<img
																							alt="grabIndicator"
																							src={grabIndicator}
																							className={styles.grabIndicator}
																						/>
																					</div>
																				</div>
																			)}
																		</Draggable>
																	)
																);
															})}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											</DragDropContext>
											{editOptionContainer}
										</div>
									)}
								</div>
							)}
					</div>
				)}
				<Modal show={openModify} centered dialogClassName={styles.modalWatchlist}>
					<Modal.Header className={styles.modalTopHeader}>
						<Modal.Title className={styles.modalTitle}>{t('wtr:MODIFY_WATCHLIST')}</Modal.Title>
					</Modal.Header>
					<Modal.Body className={styles.modifyWatchlistModalBody}>
						<div className={styles.modifyWatchlist}>
							<div className={styles.title}>{t('wtr:WATCHLIST_NAME')}</div>
							<div className={styles.modifyWatchlistInput}>
								<div className={styles.modifyInputContainer}>
									<input
										type="text"
										className={
											nameError.length !== 0 || invalidRemoveName.length !== 0
												? styles.modifyErrorInput
												: styles.modifyInput
										}
										maxLength={20}
										value={getTranslatedWLName(watchlistName)}
										onChange={changeWatchlistName}
									/>
								</div>
								<div className={styles.inputLimit}>{watchlistName.length}/20</div>
								{(nameError.length !== 0 || invalidRemoveName.length !== 0) && (
									<div className={styles.modifyErrorMessage}>
										{nameError.length > 0 ? nameError : invalidRemoveName}
									</div>
								)}
								<div
									className={cn(
										styles.buttonDiv,
										mappedWatchlist !== null && Object.keys(mappedWatchlist).length === 1 ? styles.disableBtn : ''
									)}
								>
									<Button
										label={t('wtr:REMOVE_WATCHLIST')}
										size="lg"
										onClick={removeWatchlist}
										disabled={watchlistName.trim().length === 0}
										className={cn(
											styles.modifyButton,
											(mappedWatchlist !== null && Object.keys(mappedWatchlist).length === 1) ||
												watchlistName.trim().length === 0
												? styles.disableBtn
												: ''
										)}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className={styles.modifyFooter}>
						<div className={styles.actionButtons}>
							<Button
								label={tt('CANCEL')}
								size="lg"
								variant="outline"
								className={styles.cancelButton}
								onClick={handleCloseModifyModal}
							/>
							<Button
								label={tt('SAVE')}
								size="lg"
								variant="primary"
								disabled={watchlistName.trim().length === 0}
								className={styles.saveButton}
								onClick={saveNewWatchlist}
							/>
						</div>
					</Modal.Footer>
				</Modal>
				<Modal show={openMove} dialogClassName={styles.modalWatchlist} centered scrollable={true}>
					<Modal.Header onHide={handleMoveClose} className={styles.moveModalHeader}>
						<Modal.Title className={styles.moveModalTitle}>{tt('QUOTES_EDIT_MOVE_TO')}</Modal.Title>
						<FontAwesomeIcon icon={['fas', 'times']} className={styles.closeIcon} onClick={handleMoveClose} />
					</Modal.Header>
					<Modal.Body>
						{mappedWatchlist !== null &&
							Object.keys(mappedWatchlist).map((item, index) => {
								if (
									dontShowWatchlist.includes(item) === false &&
									!instrumentExistsInDynamicWatchlists(item, dashboardContext.defaultWatchLists, isSpreadBettingAccount)
								) {
									return (
										<div key={index} className={styles.moveWatchlist}>
											<Radio
												label={getTranslatedWLName(item)}
												checked={selectedWatchlist === item}
												onChange={() => handleWatchlistSelectionOne(item)}
											/>
										</div>
									);
								}
							})}
					</Modal.Body>
					<Modal.Footer className={styles.modalFooter}>
						<div className={styles.actionButtons}>
							<Button
								label={tt('CANCEL')}
								variant="outline"
								size="lg"
								className={styles.cancelButton}
								onClick={handleMoveClose}
							/>
							<Button
								variant="primary"
								label={tt('WATCHLIST_MOVE_BTN_TITLE')}
								size="lg"
								className={
									selectedWatchlist === '' || (mappedWatchlist !== null && Object.keys(mappedWatchlist).length === 1)
										? styles.disableButton
										: styles.saveButton
								}
								onClick={moveTowatchlist}
							/>
						</div>
					</Modal.Footer>
				</Modal>
				<Modal show={openRemoveModal} centered dialogClassName={styles.modalRemoveWatchlist}>
					<Modal.Header className={styles.modalTopHeader}>
						<Modal.Title className={styles.modalTitle}>{tt('REMOVE')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.removeMessage}>{t('wtr:CONFIRM_REMOVE')}</div>
					</Modal.Body>
					<Modal.Footer className={styles.modifyFooter}>
						<div className={styles.actionButtons}>
							<Button
								variant="secondary"
								size="lg"
								label={tt('CANCEL')}
								className={styles.cancelButton}
								onClick={handleCancelRemove}
							/>
							<Button
								variant="primary"
								size="lg"
								label={tt('REMOVE')}
								className={styles.saveButton}
								onClick={handleRemoveItem}
							/>
						</div>
					</Modal.Footer>
				</Modal>
			</>
		);
	},
	(prevProps, nextProps) => {
		const keys: Array<keyof typeof prevProps> = ['window1'];
		return keys.every((key) => prevProps[key] == nextProps[key]);
	}
);

export default Watchlist;
