import React, { FC, useContext, useMemo } from 'react';
import AppContext from '../../../../../../contexts/AppContext';
import cn from 'classnames';
import styles from '../../../../Markets/MarketsGrid/MarketsGrid.module.scss';
import { formatNumberWithCommas } from '../../../Watchlist/Instrument/formattedQuoteNumber';
import quoteStore from '../../../../../../store/QuoteStore/quoteStore';
import { LowOrHighType } from '../../../../Markets/MarketsGridNew/components/LowOrHigh';
import {
	calcPercentChange,
	calcPercentChangeAtParams,
	getCurrentPrice,
} from '../../../../../../utils/functions/calculations';
import positionsStore from '../../../../../../store/PositionsStore/positionsStore';

interface PercentChangeProps {
	info: any;
}

const PercentChange = ({ info }: PercentChangeProps) => {
	const appContext = useContext(AppContext);
	const languageSettings = appContext.languageSettings;

	const quotes = quoteStore.use.quotes();

	const record = info.row.original;

	if (record.isGroupRow) {
		const currentVal = record.current ?? 0;
		const isBuy = record.isBuy ?? true;
		const openVal = +record?.oP || 0;
		const open = openVal?.toFixed(record.decPrec);
		record.change = calcPercentChangeAtParams(parseFloat(open), currentVal, isBuy);
	} else {
		record.change = record.position ? calcPercentChange(record.position) : NaN;
	}

	const isNumberPositive = useMemo(() => {
		if (!info.row.original) {
			return false;
		}

		return Number(record.change) > 0;
	}, [record.change]);

	return (
		<div
			className={cn({
				[styles.positive]: isNumberPositive,
				[styles.negative]: !isNumberPositive,
			})}
			style={{ direction: 'ltr' }}
		>
			{isNumberPositive ? '+' : ''}
			{record.change ? `${formatNumberWithCommas(record.change, 2, languageSettings)}%` : 0}
		</div>
	);
};

export default PercentChange;
