import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import Routes from '../../../../setup/routes';
import { MarketItem, SelectedSymbol } from '../../../../gateways/RfpGateway/rfp.types';
import InstrumentSearch from '../InstrumentSearch/InstrumentSearch';
import uniqueId from '../../../../utils/functions/uniqueId';

import useLogout from '../../../../utils/hooks/useLogout';
import useObservable from '../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../utils/hooks/useForceRerender';

import AppContext from '../../../../contexts/AppContext';
import ChartContext from '../../../../contexts/ChartContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import { simulateMouseEvent } from '../../../../utils/functions/simulateClick';
import { AppComponentType } from '../../../../utils/functions/enums';
import { TradersGymContext, TradersGymContextType } from '../../../../pages/TradersGym/TradersGymContext';

import Notifications from '../../../components/Notifications';

import Community from '../../../components/Community';

import authStore from '../../../../store/authStore';
import TradingViewStore from '../../../../store/tradingViewStore';

import tradingAccountStore from '../../../../store/tradingAccountStore';

import AccountMenu from './components/AccountMenu';
import AccountPanel from './AccountPanel/AccountPanel';

import styles from './Header.module.scss';

interface HeaderProps {
	showSearch: boolean;
}

const Header: React.FC<HeaderProps> = ({ showSearch }) => {
	const appContext = useContext(AppContext);
	const chartContext = useContext(ChartContext);
	const dashboardContext = useContext(DashboardContext);
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext, setGymProps } = gymContext;

	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const history = useHistory();

	const isChildWindow = appContext.isChildWindow;
	const isInTradersGymMode = tradersGymContext.isActive;

	const tradingAccount = dashboardContext.tradingAccount;
	const gridViewCharts = dashboardContext.gridViewCharts;

	const [accountPopupIsOpen, setAccountPopupIsOpen] = useState(false);
	const [openNotifications, setOpenNotifications] = useState(false);

	const [resetIdleTimer, setResetIdleTimer] = useState<boolean>(false);
	const userProfile = authStore.use.userProfile();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();
	const isSearchbarOpen = TradingViewStore.use.isSearchOpen();
	const setIsSearchbarOpen = TradingViewStore.use.setIsSearchOpen();
	const setIsCompSymbolMode = TradingViewStore.use.setIsCompSymbolMode();
	const isCompSymbolMode = TradingViewStore.use.isCompSymbolMode();

	const { start } = useIdleTimer({
		timeout: 1000 * dashboardContext.inactiveTime,
		onIdle: useLogout(),
		debounce: 500,
		startManually: true,
	});

	useEffect(() => {
		start();
	}, [resetIdleTimer]);

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'tradingAccount',
			'accountType',
			'inactiveTime',
			'quantityType',
			'selectedSymbol',
			'applicationStatus',
			'gridViewCharts',
			'showNewsFeed',
			'initialTimezone',
			'showOrderInformation',
			'showOrderTicket',
			'showCloseTicket',
			'twoFactorAuthModalToggle',
			'accountValues',
			'accountPopupToggle'
		),
		(_) => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	function filterInstruments(instrument: MarketItem, searchString: string): boolean {
		searchString = (searchString || '').trim().toLowerCase();
		return (
			searchString === '' ||
			instrument.code?.toLowerCase().includes(searchString) ||
			instrument.fullName?.toLowerCase().includes(searchString) ||
			instrument.grp?.toLowerCase().includes(searchString) ||
			(instrument.exchangeTicker ?? '')?.toLowerCase().includes(searchString)
		);
	}

	function viewInstrument(marketItem: MarketItem, gridViewCharts?: boolean) {
		if (isInTradersGymMode) {
			// Simulate chart click to close the search.
			simulateMouseEvent(document, 'mousedown', 0, 0);

			setGymProps({ searchInstrumentSelected: marketItem, isOpenCreateSimulationModal: true });
			return;
		}

		if (!gridViewCharts) {
			if (isChildWindow) {
				return;
			}
			if (window.innerWidth < 1024) {
				history.push(Routes.trader.charts);
			}

			dashboardContext.historicalData = [];
			dashboardContext.selectedInstrument = marketItem;
			dashboardContext.selectedType = 'Instrument';

			const newChartId = uniqueId();
			let selectedSymbols: SelectedSymbol = {};
			for (let i = 0; i < 2; i++) {
				selectedSymbols[newChartId] = marketItem;
			}

			dashboardContext.selectedSymbol = {
				...Object.entries(dashboardContext.selectedSymbol).slice(0, 0),
				...selectedSymbols,
			};
			dashboardContext.newChart = true;
			chartContext.setSingleActiveChart(newChartId);
		} else {
			const onTradePage =
				window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === 'watchlist';
			// Case for Markets page
			if (!onTradePage) {
				const getChartSymbol = JSON.parse(localStorage.getItem(`myChartLayoutchart0`)!);
				const updateSymbol = { ...getChartSymbol, symbols: [marketItem] };
				localStorage.setItem(`myChartLayoutchart0`, JSON.stringify(updateSymbol));
			}
			dashboardContext.selectedInstrument = marketItem;

			dashboardContext.toggleAccordionMenu = '0';
			dashboardContext.showCancelTicket = false;
			dashboardContext.showCloseTicket = false;
			if (dashboardContext.showConfirmTicket) {
				dashboardContext.showConfirmTicket = false;
				dashboardContext.showOrderTicket = false;
			}
			if (!onTradePage) {
				dashboardContext.showNewsFeed = false;
				dashboardContext.modifyTicket = false;
				dashboardContext.showOrderTicket = false;

				if (tradingAccount.length > 0) {
					dashboardContext.showOrderInformation = true;
				}
			}
			dashboardContext.selectedType = 'Instrument';
			dashboardContext.gridChartsChanged = true;

			if (!isChildWindow) {
				history.push(Routes.trader.watchlist, { from: window.location.pathname });
				dashboardContext.presentComponentType = AppComponentType.Watchlist;
			}

			simulateMouseEvent(document, 'mousedown', 0, 0);
		}
	}

	const handleCloseClick = () => {
		if (isCompSymbolMode) {
			setIsCompSymbolMode(false);
		}
		setIsSearchbarOpen(false);
	};

	return (
		<div className={styles.header}>
			<div className={styles.logoContainer}>
				<img
					className={styles.logo}
					src={appContext.appTheme === 'dark' ? '/logo.svg' : '/logo-negative.svg'}
					alt="ThinkMarkets"
				/>

				{showSearch && (
					<div
						className={isSearchbarOpen ? styles.expandedInstrumentSearchContainer : styles.instrumentSearchContainer}
					>
						<InstrumentSearch
							onOpen={() => setIsSearchbarOpen(true)}
							onClose={handleCloseClick}
							throttle={100}
							maxWatchlistNameLength={20}
							filter={filterInstruments}
							viewInstrument={(instrument) => viewInstrument(instrument, gridViewCharts)}
						/>
					</div>
				)}
			</div>

			<div className={styles.accountStats}>
				<AccountPanel isSearchBarOpen={isSearchbarOpen} />
				{userProfile.id && !isFundedTrader && <Community />}
				<Notifications openNotifications={openNotifications} setOpenNotifications={setOpenNotifications} />

				<AccountMenu
					accountPopupIsOpen={accountPopupIsOpen}
					setAccountPopupIsOpen={setAccountPopupIsOpen}
					setResetIdleTimer={setResetIdleTimer}
				/>
			</div>
		</div>
	);
};

export default Header;
