import React, { useContext, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DETACHED_SUB_ROUTES } from '../../../setup/routes';
import { useTranslation } from 'react-i18next';

import WindowContext, { WTChildWindowType } from '../../../contexts/WindowContext';
import AppContext from '../../../contexts/AppContext';

import styles from './DetachIcon.module.scss';

const DetachIcon = ({ url, windowType }: { url: string; windowType: WTChildWindowType }) => {
	const windowContext = useContext(WindowContext);
	const appContext = useContext(AppContext);

	const isChildWindow = appContext.isChildWindow;

	const isArabic = appContext.isArabic;

	const { t } = useTranslation();

	const detachWatchlistHandler = useMemo(() => {
		return () => {
			if (isChildWindow) {
				windowContext.window.close();
			} else {
				const window = windowContext.window;
				windowContext.openChildWindow(windowType, {
					url,
					// url: `${window.location.origin}${Routes.trader.detached}/window-watchlist`,
					windowName: windowType,
					// windowName: `charts${Math.random() * 100}`,
					windowFeatures: `height=${window.innerHeight},width=${window.innerWidth}`,
				});
			}
		};
	}, []);

	return (
		<div className={styles.iconContainer}>
			<OverlayTrigger
				delay={{ show: 750, hide: 0 }}
				key={'detachWatchlistIcon'}
				placement={isArabic || url === DETACHED_SUB_ROUTES.Positions ? 'left' : 'right'}
				overlay={
					<Tooltip className="my-tooltip" id={'detachWatchlist'}>
						{isChildWindow ? t('en:ATTACH') : t('en:DETACH')}
					</Tooltip>
				}
			>
				<FontAwesomeIcon
					icon={['fal', isChildWindow ? 'square-arrow-down-left' : 'square-arrow-up-right']}
					size="1x"
					onClick={detachWatchlistHandler}
				/>
			</OverlayTrigger>
		</div>
	);
};

export default DetachIcon;
