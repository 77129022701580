import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { UserProfile } from '../../store/authStore';

const useUpdateUserProfile = () => {
	const mutationFn = async (data: UserProfile) => {
		const response = await axios({
			url: '',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},

			data: {
				payload: [{ module: 'profile', action: 'update', parameters: data }],
			},
		});

		return response;
	};

	return useMutation({
		mutationKey: ['updateUserProfile'],
		mutationFn,
	});
};
export default useUpdateUserProfile;
